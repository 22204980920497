<template>
  <ion-content>
    <ion-list lines="none">
      <ion-item>
        <ion-label>Sort</ion-label>
        <ion-select
          placeholder="Direction"
          :value="joinedSortBy"
          v-model="joinedSortBy"
          @ionChange="handleSort"
        >
          <ion-select-option value="title-asc">Title - Asc</ion-select-option>
          <ion-select-option value="title-desc">Title - Desc</ion-select-option>
          <ion-select-option value="isActive-desc">Active on Top</ion-select-option>
          <ion-select-option value="isActive-asc">Active on Bottom</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label>Hide Inactive</ion-label>
        <ion-checkbox @ionChange="handleHideInactive" :checked="hideInactive"></ion-checkbox>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonList,
  IonLabel,
  IonSelectOption,
  IonSelect,
  IonItem,
  IonCheckbox,
  IonContent,
  popoverController,
} from "@ionic/vue";
import { defineComponent, onMounted, ref } from "vue";
import getLocations from "@/composables/getLocations";

export default defineComponent({
  name: "LocationsMenu",

  components: {
    IonList,
    IonLabel,
    IonSelectOption,
    IonSelect,
    IonItem,
    IonCheckbox,
    IonContent,
  },

  setup() {
    const { setHideInactive, hideInactive, setSort, sortBy } = getLocations();
    const joinedSortBy = ref("");

    onMounted(() => {
      joinedSortBy.value = sortBy.value.join("-");
    });

    const handleSort = async (e) => {
      let selectionSplit = e.detail.value.split("-");
      setSort(selectionSplit);
      popoverController.dismiss();
    };

    const handleHideInactive = async (e) => {
      hideInactive.value = e.detail.checked;
      setHideInactive(hideInactive.value);
    };

    return {
      handleHideInactive,
      hideInactive,
      handleSort,
      sortBy,
      joinedSortBy,
    };
  },
});
</script>