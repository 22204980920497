<template>
  <ion-list id="nav-list">
    <ion-list-header>
      <ion-img class="logo" src="../../assets/logo.png"></ion-img>
    </ion-list-header>
    <ion-item button lines="none" @click="handleNav('/')">
      <ion-icon slot="start" :icon="mapOutline"></ion-icon>
      <ion-label>Map</ion-label>
    </ion-item>
    <ion-item button lines="none" @click="handleHelp">
      <ion-icon slot="start" :icon="helpOutline"></ion-icon>
      <ion-label>Help</ion-label>
    </ion-item>
  </ion-list>
  <ion-list id="user-options">
    <ion-item v-if="onDevice" button lines="none" @click="handlePortal">
      <ion-icon slot="start" :icon="openOutline"></ion-icon>
      <ion-label>Technician Web Portal</ion-label>
    </ion-item>
    <ion-item v-if="!onDevice && !user.value.uid" button lines="none" @click="handlePortal">
      <ion-icon slot="start" :icon="personOutline"></ion-icon>
      <ion-label>Technician Login</ion-label>
    </ion-item>
    <ion-item button lines="none" @click="handleAccount" v-if="user.value.uid">
      <ion-icon slot="start" :icon="personOutline"></ion-icon>
      <ion-label>Account</ion-label>
    </ion-item>
    <ion-item button lines="none" @click="handleLogout" v-if="user.value.uid">
      <ion-icon slot="start" :icon="exitOutline"></ion-icon>
      <ion-label>Logout</ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
import { Plugins } from "@capacitor/core";
import { isPlatform } from "@ionic/vue";
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import getUser from "@/composables/getUser";
import useLogout from "@/composables/useLogout";

import {
  mapOutline,
  listOutline,
  personOutline,
  exitOutline,
  helpOutline,
  openOutline,
} from "ionicons/icons";

import {
  IonImg,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  alertController,
} from "@ionic/vue";

export default defineComponent({
  name: "Navigation",

  emits: ["menuItemSelected"],

  components: {
    IonImg,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
  },
  setup(props, context) {
    const onDevice = ref(false);
    const router = useRouter();
    const { Browser } = Plugins;
    const { user } = getUser();
    const { logout, error } = useLogout();

    onMounted(() => {
      if (isPlatform("mobile") && !isPlatform("mobileweb")) {
        onDevice.value = true;
      } else {
        onDevice.value = false;
      }
    });

    const handleNav = (path) => {
      context.emit("menuItemSelected");
      router.replace(path);
    };

    const handlePortal = async () => {
      context.emit("menuItemSelected");
      if (onDevice.value) {
        const portalOpenAlert = await alertController.create({
          cssClass: "my-custom-class",
          header: "Technician Web Portal",
          message:
            "If you are a repair technician and need to manage your account and locations, please continue to the web portal.",
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: (blah) => {
                console.log("Confirm Cancel:", blah);
              },
            },
            {
              text: "Continue",
              handler: async () => {
                await Browser.open({ url: "https://chipfix.app/login" });
              },
            },
          ],
        });
        portalOpenAlert.present();
      } else {
        onDevice.value = false;
        router.replace("/login");
      }
    };

    const handleAccount = () => {
      context.emit("menuItemSelected");
      router.replace({ name: "Account" });
    };

    const handleHelp = () => {
      context.emit("menuItemSelected");
      router.replace({ name: "SiteHelp" });
    };

    const handleLogout = async () => {
      await logout();
      if (!error.value) {
        context.emit("menuItemSelected");
        router.replace({ name: "SiteHome" });
      }
    };

    return {
      onDevice,
      helpOutline,
      user,
      error,
      handleNav,
      handlePortal,
      handleAccount,
      handleLogout,
      personOutline,
      openOutline,
      exitOutline,
      mapOutline,
      listOutline,
      handleHelp,
    };
  },
});
</script>

<style scoped>
.selected {
  color: #b4c540;
}
.logo {
  margin: 20px 0;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 2ƒpx;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#nav-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#nav-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#user-options ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

/* ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
} */

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

/* ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
} */

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#user-options ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

/* ion-item.selected {
  --color: var(--ion-color-primary);
} */
</style>