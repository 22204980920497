// export const GOOGLE_API_KEY = "AIzaSyDgOPHAaP_kzoDbHAkCEhMh-3BlGSLK2Wk";
// export const STRIPE_API_RK_TEST = "rk_test_516Q0IGLcMZlQpxqK1UISehiikjDZ9em7TTOhJShe8JLCDRZrGvMOC2ldP4MndwwI4IdJKov8LFzm3Yyt36oudcye00fPhTuJ89";
// export const STRIPE_API_RK_LIVE = "rk_test_516Q0IGLcMZlQpxqK1UISehiikjDZ9em7TTOhJShe8JLCDRZrGvMOC2ldP4MndwwI4IdJKov8LFzm3Yyt36oudcye00fPhTuJ89";
// export const STRIPE_API_PK_TEST = "pk_test_aVfGwiVVBRpo2q9StPyXXTP4";
// export const STRIPE_API_PK_LIVE = "pk_test_aVfGwiVVBRpo2q9StPyXXTP4";
// export const STRIPE_PRICE_39_TEST = "price_1IlCu3LcMZlQpxqK4hVZPC1L";
// export const STRIPE_PRICE_39_LIVE = "price_1IlCu3LcMZlQpxqK4hVZPC1L";

export const GOOGLE_API_KEY = "AIzaSyDgOPHAaP_kzoDbHAkCEhMh-3BlGSLK2Wk";
export const STRIPE_API_RK_LIVE = "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";
export const STRIPE_API_RK_TEST = "rk_test_516Q0IGLcMZlQpxqK1UISehiikjDZ9em7TTOhJShe8JLCDRZrGvMOC2ldP4MndwwI4IdJKov8LFzm3Yyt36oudcye00fPhTuJ89";
export const STRIPE_API_PK_LIVE = "pk_live_MokB2Jg9p9OR64Fk27C7oAz8";
export const STRIPE_API_PK_TEST = "pk_test_aVfGwiVVBRpo2q9StPyXXTP4";
export const STRIPE_PRICE_39_TEST = "price_1IlCu3LcMZlQpxqK4hVZPC1L";
export const STRIPE_PRICE_39_LIVE = "price_1Im6LPLcMZlQpxqKjPRwapfF";