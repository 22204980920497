import {
    ref
} from "vue";
import {
    projectFirestore,
}
from "../firebase";
import getUser from "./getUser";

const {
    user
} = getUser();
const location = ref(null);
const locationError = ref(null);

const loadLocation = async (id) => {
    try {
        const res = await projectFirestore.collection('locations').doc(id).get();
        location.value = {
            ...res.data()
        };
    } catch (err) {
        console.log(err.message);
        locationError.value = "Unable to load location.";
    }
}

const userOwnsLocation = async (locationId) => {
    const userId = user.value.linkedTo ? user.value.linkedTo : user.value.uid;
    await loadLocation(locationId);
    if (location.value.companyId) {
        return location.value.companyId === userId;
    } else {
        return false;
    }
}

const getLocation = () => {
    return {
        loadLocation,
        userOwnsLocation,
        location,
        locationError,
    }
}

export default getLocation;