<template>
  <ion-page>
    <ion-content>
      <ion-slides ref="slidesRef" :options="sliderOpts">
        <ion-slide>
          <ion-card class="ion-padding">
            <ion-img src="./assets/slide1.png"></ion-img>
            <ion-card-header>
              <ion-card-title>What is ChipFix?</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <p>ChipFix is an app that helps you locate active windshield chip repair technicians in your area.</p>
            </ion-card-content>
          </ion-card>
          <div class="navigator">
            <ion-button color="primary" fill="clear" @click="skip">SKIP</ion-button>
            <ion-button color="primary" fill="clear" @click="next">NEXT</ion-button>
          </div>
        </ion-slide>
        <ion-slide>
          <ion-card class="ion-padding">
            <ion-img src="./assets/slide2.png"></ion-img>
            <ion-card-header>
              <ion-card-title>Easy to Use!</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <p>Search for repair locations using your current position or by entering a city or address.</p>
            </ion-card-content>
          </ion-card>
          <div class="navigator">
            <ion-button color="primary" fill="clear" @click="skip">SKIP</ion-button>
            <ion-button color="primary" fill="clear" @click="next">NEXT</ion-button>
          </div>
        </ion-slide>
        <ion-slide class="ion-padding">
          <ion-card class="ion-padding">
            <ion-img src="./assets/slide3.png"></ion-img>
            <ion-card-header>
              <ion-card-title>On your way!</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <p>You can view repair location contact info, services offered, payment types accepted, hours of operation and navigate directly to them!</p>
              <ion-button color="primary" @click="skip">GET STARTED</ion-button>
            </ion-card-content>
          </ion-card>
        </ion-slide>
      </ion-slides>
    </ion-content>
  </ion-page>
</template>

<script>
import { Storage } from "@ionic/storage";
import { useRouter } from "vue-router";
import {
  IonSlides,
  IonSlide,
  IonContent,
  IonButton,
  IonPage,
  IonImg,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
} from "@ionic/vue";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  components: {
    IonSlides,
    IonSlide,
    IonButton,
    IonContent,
    IonPage,
    IonImg,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
  },
  setup() {
    const store = new Storage();
    const slidesRef = ref(null);
    const router = useRouter();
    const slider = ref(null);
    const sliderOpts = {
      speed: 400,
      static: false,
    };

    onMounted(async () => {
      slider.value = await slidesRef.value.$el;
      await store.create();
      await store.set("onboard", true);
    });

    const next = () => {
      slider.value.slideNext();
    };

    const skip = async () => {
      router.replace({ name: "SiteHome" });
      sliderOpts.value = {
        initialSlide: 1,
        speed: 400,
        static: false,
      };
    };

    return { sliderOpts, slidesRef, next, skip };
  },
});
</script>

<style scoped>
ion-card {
  box-shadow: none !important;
}
ion-card-header {
  padding-top: 40px;
}
ion-img {
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  max-height: 300px;
}

ion-slides {
  height: 100%;
}

/* ion-slide {
  display: block;
} */

/* ion-slide img {
  padding: 15%;
} */

/* .slider-text {
  padding-top: 20%;
} */

/* .slider-text h2 {
  font-family: "SF UI Display Semibold";
} */

.navigator {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
</style>