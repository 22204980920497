import {
    ref
} from "vue";
import {
    projectFirestore,
    projectAuth
} from "../firebase";
import {
    loadStripe
} from '@stripe/stripe-js';
import toast from "../utilities/toast";
import {
    STRIPE_API_PK_LIVE,
    STRIPE_API_PK_TEST
} from "../constants";

const subscriptionError = ref(null);
const priceId = ref('');

const selectSubscription = async () => {
    try {
        console.log("priceId.value", priceId.value);
        if (!priceId.value) {
            toast("Unable to select plan.");
            return;
        }
        const docRef = await projectFirestore
            .collection('customers')
            .doc(projectAuth.currentUser.uid)
            .collection('checkout_sessions')
            .add({
                price: priceId.value,
                success_url: process.env.NODE_ENV === "development" ? window.location.origin : "https://chipfix.app/account",
                cancel_url: process.env.NODE_ENV === "development" ? window.location.origin : "https://chipfix.app/account",
                allow_promotion_codes: true,
                trial_from_plan: false,
            });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot(async (snap) => {
            const {
                error,
                sessionId
            } = snap.data();
            if (error) {
                // Show an error to your customer and 
                // inspect your Cloud Function logs in the Firebase console.
                toast("Unable to select plan.");
                console.log(error);
                throw Error(error.message);
            }
            if (sessionId) {
                // We have a session, let's redirect to Checkout
                // Init Stripe
                const stripe = await loadStripe(process.env.NODE_ENV === "development" ?
                    STRIPE_API_PK_TEST : STRIPE_API_PK_LIVE);
                stripe.redirectToCheckout({
                    sessionId
                });
            }
        });
    } catch (err) {
        console.log(err.message);
        subscriptionError.value = "Unable to select plan.";
        toast(subscriptionError.value);
    }
}

const useSubscription = () => {
    return {
        priceId,
        subscriptionError,
        selectSubscription
    }
}

export default useSubscription;