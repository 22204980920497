<template>
  <ion-page v-if="user">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/"></ion-back-button>
        </ion-buttons>
        <ion-title>{{ user.value.email }}</ion-title>
        <ion-buttons slot="primary">
          <ion-button color="primary" @click="handleLogout">
            <ion-icon slot="icon-only" :icon="exitOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-list>
        <ion-item button @click="handleManageLocations">
          <ion-icon slot="start" :icon="!user.subscription ? cardOutline : locationOutline"></ion-icon>
          <ion-label>{{ !user.subscription ? "Select Plan" : "Manage Locations" }}</ion-label>
        </ion-item>
        <ion-item
          button
          v-if="!user.value.linkedTo"
          @click="handleManageUsers"
          :disabled="!user.subscription"
        >
          <ion-icon slot="start" :icon="peopleOutline"></ion-icon>
          <ion-label>Manage Users</ion-label>
        </ion-item>
        <!-- <ion-item-divider v-if="!user.value.linkedTo">
          <ion-label>About your Company</ion-label>
        </ion-item-divider>-->
        <ion-item
          button
          @click="handleBusinessInfo"
          v-if="!user.value.linkedTo"
          :disabled="!user.subscription"
        >
          <ion-label>Business Information</ion-label>
          <ion-icon slot="start" :icon="businessOutline"></ion-icon>
        </ion-item>
        <ion-item
          button
          v-if="user.subscription && !user.value.linkedTo"
          @click="handlePortal"
          :disabled="isLoading"
        >
          <ion-icon slot="start" :icon="cardOutline"></ion-icon>
          <ion-label>View Plan</ion-label>
        </ion-item>
        <ion-item
          button
          @click="handleCompanyCert"
          v-if="!user.value.linkedTo"
          :disabled="!user.subscription"
        >
          <ion-label>Certification Status</ion-label>
          <ion-icon slot="start" :icon="ribbonOutline"></ion-icon>
        </ion-item>
        <!-- <ion-item-divider>
          <ion-label>Account Security</ion-label>
        </ion-item-divider>-->
        <ion-item button @click="handleChangePassword">
          <ion-icon slot="start" :icon="keyOutline"></ion-icon>
          <ion-label>Change Password</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { onMounted, onUpdated, ref } from "vue";
import { useRouter } from "vue-router";
import {
  exitOutline,
  businessOutline,
  locationOutline,
  peopleOutline,
  keyOutline,
  cardOutline,
  ribbonOutline,
  documentTextOutline,
  optionsOutline,
} from "ionicons/icons";

import toast from "@/utilities/toast";
import loading from "@/utilities/loading";

import useSubscriptions from "@/composables/useSubscriptions";
import getSubscription from "@/composables/getSubscription";
import getUser from "@/composables/getUser";
import useAccount from "@/composables/useAccount";
import useLogout from "@/composables/useLogout";

import BusinessInfoModal from "@/components/Modals/BusinessInfo.vue";
import { STRIPE_PRICE_39_TEST, STRIPE_PRICE_39_LIVE } from "../../constants";

import {
  // IonItemDivider,
  IonIcon,
  IonButton,
  IonLabel,
  IonList,
  IonItem,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  alertController,
  modalController,
} from "@ionic/vue";

export default {
  name: "Account",

  components: {
    // IonItemDivider,
    IonIcon,
    IonButton,
    IonLabel,
    IonList,
    IonItem,
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
  },

  setup() {
    const router = useRouter();
    const { loadingStart, loadingStop } = loading();
    const { user, setupUser } = getUser();
    const { getBusinessInfo, changePassword } = useAccount();
    const { portal } = getSubscription();
    const { selectSubscription, priceId } = useSubscriptions();
    const { logout, error } = useLogout();

    const companyName = ref("");
    const companyBio = ref("");
    const companyIsCertified = ref(false);
    const password = ref("");
    const confirmPassword = ref("");
    const isLoading = ref(false);

    const badgePath = "assets/badge.png";
    const badgeDisabledPath = "assets/badge-disabled.png";

    onMounted(async () => {
      loadingStart(5);
      await setupUser(user.value.uid);
      loadingStop();
      let companyId = null;
      if (user.value.linkedTo) {
        companyId = user.value.linkedTo;
      } else {
        companyId = user.value.uid;
      }
      const companyInfo = await getBusinessInfo(companyId);
      companyName.value = companyInfo.companyName;
      companyBio.value = companyInfo.companyBio;
      companyIsCertified.value = companyInfo.isCertified;
    });

    onUpdated(() => {
      isLoading.value = false;
    });

    const handleBusinessInfo = async () => {
      const modal = await modalController.create({
        component: BusinessInfoModal,
        cssClass: "my-custom-class",
      });
      return modal.present();
    };

    const handleCompanyCert = async () => {
      const enabledAlert = await alertController.create({
        cssClass: "my-custom-class",
        message: `<img src="${badgePath}" class="badge"><br>Thank you for confirming your certification! Customers will be able to see that you are a certified technician when they visit your location pages.`,
        buttons: [
          {
            text: "OK",
            role: "cancel",
            cssClass: "secondary",
          },
        ],
      });
      const disabledAlert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Certification Status: Not Verified",
        message: `<img src="${badgeDisabledPath}" class="badge"><p>To get a ChipFix verified certification badge enabled on your locations, please email proof of your certification to <a href='mailto:email@chipfix.app?subject=Certification Badge Request'>email@chipfix.app</a>. Once verified, the badge will be enabled on all of your locations.</p>`,
        buttons: [
          {
            text: "OK",
            role: "cancel",
            cssClass: "secondary",
          },
        ],
      });
      return companyIsCertified.value
        ? enabledAlert.present()
        : disabledAlert.present();
    };

    const handleManageLocations = async () => {
      if (!user.subscription) {
        priceId.value =
          process.env.NODE_ENV === "development"
            ? STRIPE_PRICE_39_TEST
            : STRIPE_PRICE_39_LIVE;
        loadingStart(20);
        await selectSubscription();
        loadingStop();
        toast("Connecting to portal...");
        router.push({ name: "SiteHome" });
      } else {
        router.push({ name: "LocationList" });
      }
    };

    const handlePlanSelection = () => {
      router.push({ name: "Plans" });
    };

    const handlePortal = async () => {
      loadingStart(20);
      isLoading.value = true;
      await portal();
      loadingStop();
      isLoading.value = false;
    };

    const handleChangePassword = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Change your password",
        inputs: [
          {
            name: "newPassword",
            type: "password",
            placeholder: "New Password",
          },
          {
            name: "confirmPassword",
            type: "password",
            placeholder: "Confirm Password",
          },
        ],
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Change",
            handler: async (alertData) => {
              if (alertData.newPassword === alertData.confirmPassword) {
                loading(5);
                const res = changePassword(alertData.newPassword);
                if (res) {
                  toast("Your password has been changed.");
                }
              } else {
                toast("Please make sure the passwords match.");
              }
            },
          },
        ],
      });
      return alert.present();
    };

    const handleManageUsers = () => {
      router.push({ name: "AccountUserList" });
    };

    const handleLogout = async () => {
      await logout();
      if (!error.value) {
        router.replace({ name: "SiteHome" });
      }
    };

    return {
      user,
      isLoading,
      companyName,
      password,
      handleBusinessInfo,
      handleCompanyCert,
      handleLogout,
      handlePortal,
      handlePlanSelection,
      handleChangePassword,
      handleManageUsers,
      handleManageLocations,
      confirmPassword,
      exitOutline,
      businessOutline,
      locationOutline,
      ribbonOutline,
      documentTextOutline,
      peopleOutline,
      keyOutline,
      cardOutline,
      optionsOutline,
      companyIsCertified,
    };
  },
};
</script>

<style scoped>
.badge {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>