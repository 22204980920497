<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Technician Login</ion-title>
        <ion-buttons slot="primary">
          <ion-button color="primary" @click="handleSignup">Sign Up</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="false">
      <ion-grid>
        <ion-row>
          <ion-col sizeSm="6" offsetSm="3">
            <ion-card>
              <ion-card-content>
                <form @submit.prevent="checkForm">
                  <ion-list>
                    <ion-row>
                      <ion-col>
                        <ion-item>
                          <ion-label position="floating">Email</ion-label>
                          <ion-input
                            type="email"
                            v-model="email"
                            required
                          ></ion-input>
                        </ion-item>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col>
                        <ion-item>
                          <ion-label position="floating">Password</ion-label>
                          <ion-input
                            :type="showPassword ? 'text' : 'password'"
                            v-model="password"
                            required
                          ></ion-input>
                          <ion-icon
                            :icon="showPassword ? eyeOutline : eyeOffOutline"
                            @click="toggleShowPassword"
                            slot="end"
                          ></ion-icon>
                        </ion-item>
                      </ion-col>
                    </ion-row>
                  </ion-list>
                  <ion-button
                    @click="checkForm"
                    color="primary"
                    size="large"
                    expand="block"
                    >Login</ion-button
                  >
                  <div
                    button
                    class="ion-text-center ion-padding"
                    @click="handleResetPassword"
                  >
                    Reset Password
                  </div>
                </form>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { isPlatform } from "@ionic/vue";
import { Plugins } from "@capacitor/core";
import { onUpdated, ref } from "vue";
import { useRouter } from "vue-router";
import loading from "@/utilities/loading";
import toast from "@/utilities/toast";

import { eyeOutline, eyeOffOutline } from "ionicons/icons";

import useLogin from "@/composables/useLogin";
import useAccount from "@/composables/useAccount";

import {
  IonIcon,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonList,
  IonButton,
  IonItem,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

export default {
  name: "Login",

  components: {
    IonIcon,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonInput,
    IonList,
    IonButton,
    IonItem,
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
  },

  setup() {
    const { Browser } = Plugins;
    const { loadingStart, loadingStop } = loading();
    const router = useRouter();
    const { login, loginError } = useLogin();
    const { resetPassword } = useAccount();

    const email = ref("");
    const password = ref("");
    const showPassword = ref(false);

    onUpdated(() => {
      loadingStop();
    });

    const checkForm = () => {
      if (!email.value) {
        toast("Please enter your email address.");
      } else if (!password.value) {
        toast("Please enter your password.");
      } else if (password.value.length < 6) {
        toast("Incorrect login credentials. Please try again.");
      } else if (email.value && password.value) {
        return handleLogin();
      }
    };

    const toggleShowPassword = () => {
      showPassword.value = !showPassword.value;
    };

    const handleLogin = async () => {
      loadingStart(5);
      await login(email.value, password.value);
      loadingStop();
      if (!loginError.value) {
        router.replace({ name: "Account" });
      }
    };

    const handleSignup = async () => {
      if (isPlatform("desktop") || isPlatform("mobileweb")) {
        router.replace({ name: "SiteSelectPlan" });
      } else {
        toast("Connecting to portal...");
        loadingStart(5);
        await Browser.open({ url: "https://chipfix.app/select-plan" });
        // await Browser.open({ url: "http://192.168.1.21:8100/select-plan" });
        loadingStop();
        // router.replace({ name: "SiteSelectPlan" });
      }
    };

    const handleResetPassword = async () => {
      if (!email.value) {
        return toast("Please enter your email address.");
      }
      loadingStart(5);
      await resetPassword(email.value);
      toast("Please check your email.");
      loadingStop();
    };

    return {
      email,
      password,
      checkForm,
      handleResetPassword,
      toggleShowPassword,
      showPassword,
      handleSignup,
      eyeOutline,
      eyeOffOutline,
    };
  },
};
</script>

<style scoped>
</style>