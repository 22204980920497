import axios from "axios";
import {
    ref
} from "vue";
import {
    projectStorage
}
from "../firebase";
import toast from "../utilities/toast";
import {
    GOOGLE_API_KEY
} from "../constants";

const getStaticMapFromCoords = async (lat, lng) => {
    const res = await axios
        .get("https://maps.googleapis.com/maps/api/staticmap", {
            params: {
                center: `${lat},${lng}`,
                zoom: 18,
                size: "640x320",
                maptype: "roadmap",
                markers: `color:red|label:x|${lat},${lng}`,
                key: GOOGLE_API_KEY,
            },
            responseType: 'blob'
        });
    return res.data;
}

const useStorage = () => {
    const storageError = ref(null);

    const saveFile = async (coords, locationId, userId) => {
        try {
            const image = await getStaticMapFromCoords(coords.lat, coords.lng);
            const storageRef = projectStorage.ref(`location_maps/${userId}/${locationId}.png`);
            await storageRef.put(image);
            return await storageRef.getDownloadURL();
        } catch (err) {
            console.log(err.message);
            storageError.value = 'Unable to find location.';
            await toast(storageError.value);
            return null;
        }
    }

    return {
        storageError,
        saveFile
    }
}

export default useStorage;