<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/account"></ion-back-button>
        </ion-buttons>
        <ion-title>Locations</ion-title>
        <ion-buttons slot="primary">
          <ion-button @click="handleOptionsMenu">
            <ion-icon slot="icon-only" color="primary" :icon="optionsSharp"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="user">
      <ion-item-divider sticky>
        <ion-label>
          <ion-icon :icon="informationCircleOutline" @click="handleListInfo"></ion-icon>
        </ion-label>
        <ion-note slot="end" class="ion-padding">
          {{ activeLocationsCount }} / {{ numOfAllowedLocations }} Active
          Locations
        </ion-note>
      </ion-item-divider>
      <ion-list ref="slidingItems">
        <ion-item-sliding v-for="location in allLocations" :key="location.id">
          <ion-item @click="handleLocationDetails(location)">
            <ion-label :class="location.isActive ? 'active-location' : ''">
              <h2>{{ location.title }}</h2>
              <p>{{ location.address }}</p>
            </ion-label>
          </ion-item>

          <ion-item-options side="start">
            <ion-item-option color="light">
              <!-- <ion-icon slot="icon-only" :icon="sendSharp"></ion-icon> -->
              <span v-if="!location.isActive && !canActivate" @click="handleAlert">
                <ion-toggle
                  :disabled="!canActivate || !user.value.canManageLocations"
                  :checked="location.isActive"
                ></ion-toggle>
              </span>
              <ion-toggle
                v-else
                :disabled="!user.value.canManageLocations"
                @ionChange="handleLocationToggle($event, location.id)"
                :checked="location.isActive"
              ></ion-toggle>
            </ion-item-option>
          </ion-item-options>

          <ion-item-options side="end">
            <ion-item-option
              :disabled="!user.value.canManageLocations"
              color="danger"
              @click="presentDeleteConfirm(location.id)"
            >
              <ion-icon slot="icon-only" :icon="trashBinSharp"></ion-icon>
            </ion-item-option>
            <ion-item-option
              :disabled="!user.value.canManageLocations"
              color="secondary"
              @click="handleEditLocation(location.id)"
            >
              <ion-icon slot="icon-only" :icon="pencilSharp"></ion-icon>
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </ion-list>
    </ion-content>
    <ion-content v-else-if="allLocations?.length === 0">
      <ion-item-divider sticky>
        <ion-note slot="end" class="ion-padding">
          {{ activeLocationsCount }} / {{ numOfAllowedLocations }} Active
          Locations
        </ion-note>
      </ion-item-divider>
    </ion-content>
    <!-- <ion-content v-else>
      <ion-text color="primary">
        <ion-spinner name="dots"></ion-spinner>
      </ion-text>
    </ion-content>-->
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="handleNewLocation" :disabled="!user.value.canManageLocations">
        <ion-icon :icon="addSharp"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-page>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

import getLocations from "@/composables/getLocations";
import useLocation from "@/composables/useLocation";
import getSubscription from "@/composables/getSubscription";
import getUser from "@/composables/getUser";
import LocationListMenu from "@/components/Menus/LocationListMenu";
import loading from "@/utilities/loading";

import {
  optionsSharp,
  addSharp,
  trashBinSharp,
  pencilSharp,
  sendSharp,
  informationCircleOutline,
} from "ionicons/icons";

import {
  IonNote,
  IonItemDivider,
  IonButton,
  IonIcon,
  IonFab,
  IonFabButton,
  IonToggle,
  IonList,
  IonItemOptions,
  IonItemOption,
  IonItemSliding,
  IonButtons,
  IonContent,
  IonHeader,
  IonBackButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  alertController,
  popoverController,
} from "@ionic/vue";

export default {
  name: "LocationView",

  components: {
    IonNote,
    IonItemDivider,
    IonButton,
    IonIcon,
    IonFab,
    IonFabButton,
    IonToggle,
    IonList,
    IonItemOptions,
    IonItemOption,
    IonItemSliding,
    IonButtons,
    IonContent,
    IonHeader,
    IonBackButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonLabel,
  },

  setup() {
    const { loadingStart, loadingStop } = loading();
    const { deleteDoc, toggleActive } = useLocation();
    const { loadSubscription, portal } = getSubscription();
    const { user } = getUser();
    const {
      loadLocations,
      locations,
      locationsError,
      hideInactive,
      activeLocationsCount,
      unloadLocations,
    } = getLocations();

    const router = useRouter();
    const numOfAllowedLocations = ref(null);

    const allLocations = ref({});
    const slidingItems = ref(null);

    watch(locations, (val) => {
      allLocations.value = val;
      filterList(hideInactive.value);
    });

    watch(hideInactive, (val) => {
      filterList(val);
    });

    onMounted(async () => {
      await loadingStart(5);
      loadLocations();
      await loadSubscription();
      if (user.subscription) {
        numOfAllowedLocations.value = user.subscription.quantity;
      }
      loadingStop();
    });

    onUnmounted(() => {
      unloadLocations();
    });

    const filterList = (filter) => {
      // await slidingItems?.value?.$el.closeSlidingItems();
      let currentList = locations.value;
      if (filter) {
        // allLocations.value = Object.values(currentList).filter((location) => {
        allLocations.value = currentList.filter((location) => {
          return location.isActive === filter;
        });
      } else {
        allLocations.value = currentList;
      }
    };

    const handleListInfo = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Swipe Locations",
        // subHeader: "To access options",
        message: `Swipe a location from left to right to access the location activation toggle. Locations that are toggled on will show up on the map. Locations that are toggled off will not show up on the map.<br><br>Swipe locations from right to left for quick access to edit and delete buttons for that location.`,
        buttons: ["OK"],
      });
      return alert.present();
    };

    const handleLocationToggle = async (e, id) => {
      if (user.value.canManageLocations) {
        await toggleActive(id, e.detail.checked);
      }
    };

    const handleNewLocation = () => {
      router.push({ name: "LocationNew" });
    };

    const handleLocationDetails = (location) => {
      router.push({ name: "LocationDetails", params: { id: location.id } });
    };

    const handleAlert = async () => {
      if (user.value.canManageLocations) {
        let message = `Your current plan allows you to have ${
          activeLocationsCount.value
        } active location${
          activeLocationsCount.value === 1 ? "" : "s"
        } at the same time. To enable additional active locations, please update the quantity in your plan page.`;
        let text = "View Plan";
        let buttons = [];
        if (user.value.linkedTo) {
          message = `Account owner must upgrade to allow more than ${
            activeLocationsCount.value
          } location${
            activeLocationsCount.value === 1 ? "" : "s"
          } to be active at the same time.`;
          text = "Okay";
        } else {
          buttons = [
            {
              text,
              handler: async () => {
                loadingStart(20);
                await portal();
              },
            },
          ];
        }
        const alert = await alertController.create({
          cssClass: "my-custom-class",
          header: "Maximum number of locations active",
          message,
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
            },
            ...buttons,
          ],
        });
        return alert.present();
      }
    };

    const handleOptionsMenu = async (e) => {
      const popover = await popoverController.create({
        component: LocationListMenu,
        cssClass: "my-custom-class",
        event: e,
        translucent: true,
      });
      return popover.present();
    };

    const handleEditLocation = async (id) => {
      await slidingItems?.value?.$el.closeSlidingItems();
      router.push({ name: "LocationEdit", params: { id: id } });
    };

    const presentDeleteConfirm = async (id) => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Are you sure?",
        message: "This action can not be undone.",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: async () => {
              await slidingItems?.value?.$el.closeSlidingItems();
            },
          },
          {
            text: "Delete",
            handler: async () => {
              loadingStart(5);
              await deleteDoc(id);
              loadingStop();
            },
          },
        ],
      });
      return alert.present();
    };

    return {
      user,
      canActivate: computed(
        () => activeLocationsCount.value < numOfAllowedLocations.value
      ),
      handleListInfo,
      informationCircleOutline,
      optionsSharp,
      handleAlert,
      slidingItems,
      locationsError,
      allLocations,
      addSharp,
      trashBinSharp,
      pencilSharp,
      sendSharp,
      numOfAllowedLocations,
      activeLocationsCount,
      handleLocationToggle,
      handleLocationDetails,
      handleEditLocation,
      presentDeleteConfirm,
      handleNewLocation,
      handleOptionsMenu,
    };
  },
};
</script>

<style scoped>
.active-location {
  --color: #e96432;
}

.no-locations {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>