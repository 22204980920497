import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions"
import * as geofirestore from 'geofirestore';

const firebaseConfig = {
    apiKey: "AIzaSyAf7vcxUVcms5tc5OlR2-fBFv_FNaPIgbI",
    authDomain: "chipfixapp.firebaseapp.com",
    projectId: "chipfixapp",
    storageBucket: "chipfixapp.appspot.com",
    messagingSenderId: "823991267895",
    appId: "1:823991267895:web:f09c88aa35cf56bcfec5b2",
    measurementId: "G-EHSQZS6BDM"
};


const secondaryProject = firebase.initializeApp(firebaseConfig, "Secondary");

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

const projectAuth = firebase.auth();
const secondaryAuth = secondaryProject.auth();
const projectFirestore = firebase.firestore();
const projectStorage = firebase.storage();
const projectFunction = firebase.functions();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;
const geoFirestore = geofirestore.initializeApp(projectFirestore);
const geoPoint = firebase.firestore.GeoPoint;

// if (location.hostname === "localhost") {
//     projectAuth.useEmulator('http://localhost:9099/');
//     projectFirestore.useEmulator("localhost", 8080);
// }

export {
    analytics,
    projectAuth,
    secondaryAuth,
    projectFirestore,
    projectStorage,
    projectFunction,
    timestamp,
    geoFirestore,
    geoPoint,
}