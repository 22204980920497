<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>Business Info</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="modalController.dismiss()">Cancel</ion-button>
        <ion-button color="primary" @click="closeModal">Save</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list>
      <ion-item>
        <ion-label position="stacked">Name of your Company</ion-label>
        <ion-input v-model="businessName"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">About your Business</ion-label>
        <ion-textarea
          rows="6"
          placeholder="Enter information about your business that you would like your customers to know."
          v-model="businessBio"
        ></ion-textarea>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import getUser from "@/composables/getUser";
import useAccount from "@/composables/useAccount";
import {
  IonInput,
  IonTextarea,
  IonTitle,
  IonButtons,
  IonButton,
  IonToolbar,
  IonHeader,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  modalController,
} from "@ionic/vue";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  components: {
    IonInput,
    IonTextarea,
    IonTitle,
    IonButtons,
    IonButton,
    IonToolbar,
    IonHeader,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
  },

  setup() {
    const { user } = getUser();
    const { getBusinessInfo, updateBusinessInfo } = useAccount();

    const businessName = ref("");
    const businessBio = ref("");

    onMounted(async () => {
      let info = await getBusinessInfo(user.value.uid);
      if (info) {
        businessName.value = info.companyName;
        businessBio.value = info.companyBio;
      }
    });

    const closeModal = () => {
      modalController.dismiss();
      const info = {
        companyName: businessName.value,
        companyBio: businessBio.value,
      };
      updateBusinessInfo(user.value.uid, info);
    };

    return {
      closeModal,
      modalController,
      businessName,
      businessBio,
    };
  },
});
</script>