import {
    toastController,
} from "@ionic/vue";


const toast = async (message) => {
    const t = await toastController.create({
        message: message,
        duration: 3000,
        buttons: [{
            text: 'OK',
            role: 'cancel',
        }]
    });
    t.present();
}

export default toast;