<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/locations"></ion-back-button>
        </ion-buttons>
        <ion-title>Location Details</ion-title>
        <ion-buttons slot="primary">
          <ion-button @click="handleOptionsMenu">
            <ion-icon slot="icon-only" :icon="ellipsisVertical"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="location">
      <ion-grid>
        <ion-row>
          <ion-col sizeSm="8" offsetSm="2">
            <ion-card>
              <ion-card-header>
                <ion-card-title>{{ location.title }}</ion-card-title>
                <ion-card-subtitle v-if="location.notes">internal notes: {{ location.notes }}</ion-card-subtitle>
              </ion-card-header>

              <ion-card-content>
                <ion-item v-if="location.phone" lines="none" button :href="`tel:${location.phone}`">
                  <ion-icon :icon="callOutline" slot="start"></ion-icon>
                  <ion-label>{{ location.phone }}</ion-label>
                </ion-item>
                <ion-item
                  v-if="location.email"
                  lines="none"
                  buttonß
                  :href="`mailto:${location.email}`"
                >
                  <ion-icon :icon="mailOutline" slot="start"></ion-icon>
                  <ion-label>{{ location.email }}</ion-label>
                </ion-item>
              </ion-card-content>
            </ion-card>

            <ion-card v-if="location.coordinates">
              <ion-item lines="none">
                <ion-icon slot="start" :icon="locationOutline"></ion-icon>
                <ion-label>{{ location.address }}</ion-label>
              </ion-item>
              <ion-img v-if="location.imageUrl" :src="location.imageUrl" />
            </ion-card>

            <ion-card v-if="location.locationTypes?.length">
              <ion-card-header>
                <ion-card-title>Location Type</ion-card-title>
              </ion-card-header>
              <ion-list lines="none">
                <ion-item v-for="entry in location.locationTypes" :key="entry.label">
                  <ion-icon
                    slot="start"
                    :color="entry.isChecked ? 'success' : ''"
                    :icon="
                      entry.isChecked ? checkboxOutline : closeCircleOutline
                    "
                  ></ion-icon>
                  <ion-label class="ion-text-wrap">
                    {{
                    entry.label !== "Other"
                    ? entry.label
                    : entry.value !== ""
                    ? entry.value
                    : "Other"
                    }}
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-card>

            <ion-card v-if="location.appointmentTypes?.length">
              <ion-card-header>
                <ion-card-title>Appointment Options</ion-card-title>
              </ion-card-header>
              <ion-list lines="none">
                <ion-item v-for="entry in location.appointmentTypes" :key="entry.label">
                  <ion-icon
                    slot="start"
                    :color="entry.isChecked ? 'success' : ''"
                    :icon="
                      entry.isChecked ? checkboxOutline : closeCircleOutline
                    "
                  ></ion-icon>
                  <ion-label class="ion-text-wrap">
                    {{
                    entry.label !== "Other"
                    ? entry.label
                    : entry.value !== ""
                    ? entry.value
                    : "Other"
                    }}
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-card>

            <ion-card v-if="location.services?.length">
              <ion-card-header>
                <ion-card-title>Services Offered</ion-card-title>
              </ion-card-header>
              <ion-list lines="none">
                <ion-item v-for="entry in location.services" :key="entry.label">
                  <ion-icon
                    slot="start"
                    :color="entry.isChecked ? 'success' : ''"
                    :icon="
                      entry.isChecked ? checkboxOutline : closeCircleOutline
                    "
                  ></ion-icon>
                  <ion-label class="ion-text-wrap">
                    {{
                    entry.label !== "Other"
                    ? entry.label
                    : entry.value !== ""
                    ? entry.value
                    : "Other"
                    }}
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-card>

            <ion-card v-if="location.paymentTypes?.length">
              <ion-card-header>
                <ion-card-title>Payment Types Accepted</ion-card-title>
              </ion-card-header>
              <ion-list lines="none">
                <ion-item v-for="entry in location.paymentTypes" :key="entry.label">
                  <ion-icon
                    slot="start"
                    :color="entry.isChecked ? 'success' : ''"
                    :icon="
                      entry.isChecked ? checkboxOutline : closeCircleOutline
                    "
                  ></ion-icon>
                  <ion-label class="ion-text-wrap">
                    {{
                    entry.label !== "Other"
                    ? entry.label
                    : entry.value !== ""
                    ? entry.value
                    : "Other"
                    }}
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-card>

            <ion-card v-if="location.hours?.length">
              <ion-card-header>
                <ion-card-title>Hours of Operation</ion-card-title>
              </ion-card-header>
              <ion-list>
                <ion-item v-for="day in location.hours" :key="day.label">
                  <ion-label>{{ day.label }}</ion-label>
                  <ion-datetime
                    v-if="day.active"
                    display-format="h:mm a"
                    :value="day.open"
                    readonly
                  ></ion-datetime>
                  <ion-datetime
                    v-if="day.active"
                    display-format="h:mm a"
                    :value="day.close"
                    readonly
                  ></ion-datetime>
                  <ion-label class="ion-text-end" v-else>Closed</ion-label>
                </ion-item>
              </ion-list>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-content v-else-if="error">
      <div class="spin">
        <h3>{{ error }}</h3>
        <ion-button :router-link="{ name: 'LocationList' }">Back to Locations</ion-button>
      </div>
    </ion-content>
    <ion-content v-else>
      <div class="spin">
        <ion-spinner name="dots"></ion-spinner>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
// import { onMounted } from "vue";

import getLocation from "@/composables/getLocation";
// import loading from "@/utilities/loading";

import LocationDetailsMenu from "@/components/Menus/LocationDetailsMenu";

import {
  IonRow,
  IonCol,
  IonGrid,
  IonDatetime,
  IonList,
  IonSpinner,
  IonImg,
  IonButton,
  IonCardHeader,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonLabel,
  IonItem,
  IonButtons,
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  popoverController,
} from "@ionic/vue";

import {
  addSharp,
  ellipsisVertical,
  checkboxOutline,
  closeCircleOutline,
  callOutline,
  mailOutline,
  locationOutline,
} from "ionicons/icons";

export default {
  name: "LocationDetail",

  components: {
    IonRow,
    IonCol,
    IonGrid,
    IonDatetime,
    IonList,
    IonSpinner,
    IonImg,
    IonButton,
    IonCardHeader,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
    IonLabel,
    IonItem,
    IonButtons,
    IonBackButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
  },

  props: ["id"],

  setup(props) {
    // const { loadingStart, loadingStop } = loading();
    const { location, error } = getLocation();

    // onMounted(async () => {
    //   if (!location.value) {
    //     loadingStart(5);
    //     // await loadLocation(props.id);
    //     loadingStop();
    //   }
    // });

    const handleOptionsMenu = async (e) => {
      const popover = await popoverController.create({
        component: LocationDetailsMenu,
        cssClass: "my-custom-class",
        componentProps: { id: props.id },
        event: e,
        translucent: true,
      });
      return popover.present();
    };

    return {
      callOutline,
      mailOutline,
      checkboxOutline,
      closeCircleOutline,
      locationOutline,
      ellipsisVertical,
      error,
      location,
      addSharp,
      handleOptionsMenu,
    };
  },
};
</script>

<style scoped>
.spin {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
ion-spinner {
  width: 28px;
  height: 28px;
  stroke: #444;
  fill: #222;
}
/* Custom Skeleton Line Height and Margin */
.custom-skeleton ion-skeleton-text {
  line-height: 192px;
}

.custom-skeleton ion-skeleton-text:last-child {
  margin-bottom: 0px;
}
</style>