/* eslint-disable no-unused-vars */
<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/locations"></ion-back-button>
        </ion-buttons>
        <ion-title>New Location</ion-title>
        <ion-buttons slot="primary">
          <ion-button color="primary" @click="checkForm">Save</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <form @submit.prevent="checkForm">
        <ion-grid>
          <ion-row>
            <ion-col sizeSm="8" offsetSm="2">
              <ion-card>
                <ion-list>
                  <ion-list-header>
                    <ion-label>Location Information</ion-label>
                  </ion-list-header>
                  <ion-item>
                    <ion-label position="floating">Title</ion-label>
                    <ion-input v-model="title"></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Address</ion-label>
                    <ion-input v-model="address"></ion-input>
                    <ion-icon :icon="mapSharp" slot="end" @click="presentMap"></ion-icon>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Phone</ion-label>
                    <ion-input v-model="phone" type="tel"></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Email</ion-label>
                    <ion-input v-model="email" type="email"></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Location Notes (internal use)</ion-label>
                    <ion-textarea v-model="notes"></ion-textarea>
                  </ion-item>
                </ion-list>
              </ion-card>

              <ion-card>
                <ion-list lines="none">
                  <ion-list-header>
                    <ion-label>Location Type</ion-label>
                  </ion-list-header>
                  <ion-item v-for="entry in locationTypes" :key="entry.label">
                    <ion-checkbox
                      slot="start"
                      :value="entry.isChecked"
                      :checked="entry.isChecked"
                      @ionChange="entry.isChecked = !entry.isChecked"
                    ></ion-checkbox>
                    <ion-label v-if="entry.label !== 'Other'">{{ entry.label }}</ion-label>
                    <ion-textarea v-else placeholder="Other..." v-model="entry.value"></ion-textarea>
                  </ion-item>
                </ion-list>
              </ion-card>

              <ion-card>
                <ion-list lines="none">
                  <ion-list-header>
                    <ion-label>Appointment Options</ion-label>
                  </ion-list-header>
                  <ion-item v-for="entry in appointmentTypes" :key="entry.label">
                    <ion-checkbox
                      slot="start"
                      :value="entry.isChecked"
                      :checked="entry.isChecked"
                      @ionChange="entry.isChecked = !entry.isChecked"
                    ></ion-checkbox>
                    <ion-label v-if="entry.label !== 'Other'">{{ entry.label }}</ion-label>
                    <ion-textarea v-else placeholder="Other..." v-model="entry.value"></ion-textarea>
                  </ion-item>
                </ion-list>
              </ion-card>

              <ion-card>
                <ion-list lines="none">
                  <ion-list-header>
                    <ion-label>Services Offered</ion-label>
                  </ion-list-header>
                  <ion-item v-for="entry in services" :key="entry.label">
                    <ion-checkbox
                      slot="start"
                      :value="entry.isChecked"
                      :checked="entry.isChecked"
                      @ionChange="entry.isChecked = !entry.isChecked"
                    ></ion-checkbox>
                    <ion-label v-if="entry.label !== 'Other'">{{ entry.label }}</ion-label>
                    <ion-textarea v-else placeholder="Other..." v-model="entry.value"></ion-textarea>
                  </ion-item>
                </ion-list>
              </ion-card>

              <ion-card>
                <ion-list lines="none">
                  <ion-list-header>
                    <ion-label>Payment Types Accepted</ion-label>
                  </ion-list-header>
                  <ion-item v-for="entry in paymentTypes" :key="entry.label">
                    <ion-checkbox
                      slot="start"
                      :value="entry.isChecked"
                      :checked="entry.isChecked"
                      @ionChange="entry.isChecked = !entry.isChecked"
                    ></ion-checkbox>
                    <ion-label v-if="entry.label !== 'Other'">{{ entry.label }}</ion-label>
                    <ion-textarea v-else placeholder="Other..." v-model="entry.value"></ion-textarea>
                  </ion-item>
                </ion-list>
              </ion-card>

              <ion-card>
                <ion-list>
                  <ion-list-header>
                    <ion-label>Hours of Operation</ion-label>
                  </ion-list-header>
                  <ion-item v-for="day in hours" :key="day.label">
                    <ion-checkbox
                      slot="start"
                      :value="day.active"
                      :checked="day.active"
                      @ionChange="day.active = !day.active"
                    ></ion-checkbox>
                    <ion-label>{{ day.label }}</ion-label>
                    <ion-datetime
                      display-format="h:mm a"
                      v-model="day.open"
                      :value="day.open"
                      :disabled="!day.active"
                    ></ion-datetime>
                    <ion-datetime
                      display-format="h:mm a"
                      v-model="day.close"
                      :value="day.close"
                      :disabled="!day.active"
                    ></ion-datetime>
                  </ion-item>
                </ion-list>
                <ion-list v-if="errors.length > 0">
                  <ion-item v-for="error in errors" :key="error">{{ error }}</ion-item>
                </ion-list>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";

import useGeocode from "@/composables/useGeocode";
import getUser from "@/composables/getUser";
import useLocation from "@/composables/useLocation";
import useStorage from "@/composables/useStorage";
import { geoPoint } from "@/firebase";
import manageMap from "@/composables/manageMap";

import toast from "@/utilities/toast";
import loading from "@/utilities/loading";

import LocationSelector from "@/components/Map/LocationSelector";
import { mapSharp } from "ionicons/icons";

import {
  IonRow,
  IonCol,
  IonCard,
  IonGrid,
  IonTextarea,
  IonListHeader,
  IonCheckbox,
  IonDatetime,
  IonIcon,
  IonButton,
  IonInput,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  alertController,
  modalController,
} from "@ionic/vue";

export default {
  name: "AddLocation",

  components: {
    IonRow,
    IonCol,
    IonCard,
    IonGrid,
    IonTextarea,
    IonListHeader,
    IonCheckbox,
    IonDatetime,
    IonIcon,
    IonButton,
    IonInput,
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
  },

  setup() {
    const { loadingStart, loadingStop } = loading();
    const {
      getCoordsFromAddress,
      getAddressFromCoords,
      geocodeError,
    } = useGeocode();
    const { saveFile } = useStorage();
    const { user } = getUser();
    const { locationError, addDoc, updateDoc } = useLocation();
    const { mapCenter, resetMapCenter } = manageMap();

    const router = useRouter();

    const title = ref("");
    const address = ref("");
    const phone = ref("");
    const email = ref("");
    const coords = ref(null);
    const staticMapUrl = ref("");

    const errors = ref([]);

    const notes = ref("");

    const services = ref([
      {
        label: "Chip Repair",
        isChecked: false,
      },
      {
        label: "Headlight Restoration",
        isChecked: false,
      },
      {
        label: "Other",
        isChecked: false,
        value: "",
      },
    ]);

    const paymentTypes = ref([
      {
        label: "Insurance",
        isChecked: false,
      },
      {
        label: "Credit/Debit Card",
        isChecked: false,
      },
      {
        label: "Cash",
        isChecked: false,
      },
      {
        label: "Other",
        isChecked: false,
        value: "",
      },
    ]);

    const locationTypes = ref([
      {
        label: "Pop-up/Tent",
        isChecked: false,
      },
      {
        label: "Building",
        isChecked: false,
      },
      {
        label: "Other",
        isChecked: false,
        value: "",
      },
    ]);

    const appointmentTypes = ref([
      {
        label: "Drive-up",
        isChecked: false,
      },
      {
        label: "Appointment",
        isChecked: false,
      },
      {
        label: "Other",
        isChecked: false,
        value: "",
      },
    ]);

    const hours = ref([
      {
        label: "Sunday",
        active: false,
        open: "08:00",
        close: "17:00",
      },
      {
        label: "Monday",
        active: false,
        open: "08:00",
        close: "17:00",
      },
      {
        label: "Tuesday",
        active: false,
        open: "08:00",
        close: "17:00",
      },
      {
        label: "Wednesday",
        active: false,
        open: "08:00",
        close: "17:00",
      },
      {
        label: "Thursday",
        active: false,
        open: "08:00",
        close: "17:00",
      },
      {
        label: "Friday",
        active: false,
        open: "08:00",
        close: "17:00",
      },
      {
        label: "Saturday",
        active: false,
        open: "08:00",
        close: "17:00",
      },
    ]);

    onMounted(() => {
      resetMapCenter();
    });

    onUnmounted(() => {
      errors.value = [];
      title.value = "";
      address.value = "";
      phone.value = "";
      email.value = "";
      notes.value = "";
      coords.value = {};
      staticMapUrl.value = "";
    });

    const checkForm = () => {
      errors.value = [];
      if (!title.value) {
        toast("A title for this location is required.");
      }
      if (!address.value) {
        toast("An address for this location is required.");
      }
      if (title.value && address.value) {
        return handleAddLocation();
      }
    };

    const handleAddLocation = async () => {
      loadingStart(5);
      if (!coords.value && address.value) {
        coords.value = await getCoordsFromAddress(address.value);
      }

      if (geocodeError.value) {
        return toast("Error");
      }

      await addDoc({
        title: title.value,
        address: address.value,
        phone: phone.value,
        email: email.value,
        notes: notes.value,
        isActive: false,
        coordinates: new geoPoint(coords.value.lat, coords.value.lng),
        hours: hours.value,
        services: services.value,
        locationTypes: locationTypes.value,
        appointmentTypes: appointmentTypes.value,
        paymentTypes: paymentTypes.value,
      })
        .then(async (doc) => {
          let imageUrl = await saveFile(
            { lat: coords.value.lat, lng: coords.value.lng },
            doc.id,
            user.value.uid
          );
          await updateDoc(doc.id, { imageUrl });
        })
        .catch((err) => console.log(err.message));
      loadingStop();
      if (!locationError.value) {
        router.go(-1);
      }
    };

    const presentConfirmCancel = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Confirm",
        message: `Are you sure you want to cancel?`,
        buttons: [
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Yes",
            handler: () => {
              router.go(-1);
            },
          },
        ],
      });
      return alert.present();
    };

    const presentMap = async () => {
      if (!coords.value?.lat && !coords.value?.lng && address.value) {
        mapCenter.value = await getCoordsFromAddress(address.value);
      }
      const modal = await modalController.create({
        component: LocationSelector,
        cssClass: "my-custom-class",
        componentProps: {
          title: "Select a location",
          coords: mapCenter.value,
          zoom: 17,
        },
      });

      modal.onWillDismiss().then(async (position) => {
        if (position.data) {
          coords.value = position.data;
          mapCenter.value = coords.value;
          address.value = await getAddressFromCoords(mapCenter.value);
        }
      });

      return modal.present();
    };

    return {
      paymentTypes,
      locationTypes,
      appointmentTypes,
      errors,
      checkForm,
      title,
      address,
      phone,
      email,
      handleAddLocation,
      presentConfirmCancel,
      presentMap,
      mapSharp,
      notes,
      hours,
      services,
    };
  },
};
</script>