<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/account"></ion-back-button>
        </ion-buttons>
        <ion-title>Technician Sign Up</ion-title>
        <ion-buttons slot="primary">
          <ion-button color="primary" @click="handleLogin">Log In</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col sizeSm="6" offsetSm="3" sizeLg="8" offsetLg="2">
            <!-- <ion-card>
            <ion-card-content>-->
            <div class="columns">
              <ul class="price">
                <li class="header">$39/Month</li>
                <li>One active location *</li>
                <li>Store unlimited locations - activate and deactivate any time you need</li>
                <li>Create sub-accounts for employees to help manage your locations</li>
                <li>Customers navigate directly to your active location(s)</li>
                <li>
                  <ion-button
                    size="large"
                    expand="block"
                    @click="handleSelectPlan()"
                    :disabled="isLoading"
                  >Sign Up</ion-button>
                </li>
              </ul>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col sizeSm="6" offsetSm="3" sizeLg="8" offsetLg="2">
            <ion-card>
              <ion-card-content class="ion-text-center">
                <!-- <ion-card-title class="ion-padding ion-text-center">Cost Calculator</ion-card-title> -->
                <ion-card-content>
                  * Active locations are locations that will show up on the map when a customer searches for repair technicains in their area.
                  <br />
                  <br />This plan includes the ability to activate any one of your stored locations at any time. After signing up, if you require multiple locations to be active at the same time, they can be added to your plan at a discounted rate of $29/month each.
                </ion-card-content>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col sizeSm="6" offsetSm="3" sizeLg="8" offsetLg="2">
            <ion-card v-if="promo?.isActive">
              <ion-card-content>
                <div class="ion-text-center">
                  <h1>{{ promo.title }}</h1>
                  <p>{{ promo.description }}</p>
                </div>
                <ion-list>
                  <ion-list-header>
                    <ion-label class="ion-text-center">
                      {{
                      promo.label
                      }}
                    </ion-label>
                  </ion-list-header>
                  <ion-item lines="none">
                    <ion-label class="ion-text-center" color="primary">
                      <h1>{{ promo.code }}</h1>
                    </ion-label>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label
                      style="color: #737373; font-size: 0.8em"
                      class="ion-text-center"
                    >{{ promo.expires }}</ion-label>
                  </ion-item>
                </ion-list>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
// import { Plugins } from "@capacitor/core";
// import toast from "@/utilities/toast";
// import { isPlatform } from "@ionic/vue";
import { STRIPE_PRICE_39_TEST, STRIPE_PRICE_39_LIVE } from "../../constants";
import { projectFirestore } from "@/firebase";
import { useRouter } from "vue-router";
import loading from "@/utilities/loading";
import getUser from "@/composables/getUser";
import useSubscriptions from "@/composables/useSubscriptions";
import { checkboxOutline } from "ionicons/icons";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonListHeader,
  IonLabel,
  IonItem,
  IonList,
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  // alertController,
} from "@ionic/vue";
import { onMounted, ref, watch } from "vue";

export default {
  name: "Plans",

  components: {
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonListHeader,
    IonLabel,
    IonItem,
    IonList,
    IonButton,
    IonButtons,
    IonBackButton,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonTitle,
  },

  setup() {
    // const { App } = Plugins;
    const router = useRouter();
    const { loadingStart, loadingStop } = loading();
    const { user } = getUser();
    const { priceId, selectSubscription } = useSubscriptions();
    const isLoading = ref(false);
    const promo = ref(null);

    const averageProfit = ref(null);
    const numLocations = ref(null);
    const averageMinutes = ref(null);
    const calcResult = ref(false);
    const calcCost = ref(null);
    const calcTime = ref(null);
    const calcRepairs = ref(null);

    watch(averageProfit, () => {
      calcResult.value = false;
    });

    watch(numLocations, () => {
      calcResult.value = false;
    });

    watch(averageMinutes, () => {
      calcResult.value = false;
    });

    const calculateCost = () => {
      if (parseInt(numLocations.value) === 1) {
        calcCost.value = 99 * numLocations.value;
      } else if (parseInt(numLocations.value) > 1) {
        calcCost.value = (numLocations.value - 1) * 50 + 99;
      }
      calcRepairs.value = Math.ceil(calcCost.value / averageProfit.value);
      calcResult.value = true;
      calcTime.value = Math.ceil(
        parseInt(calcRepairs.value) * (parseInt(averageMinutes.value) / 60)
      );
    };

    onMounted(async () => {
      const res = await projectFirestore
        .collection("promo")
        .doc("2a08zX1wZ7H7xIIJqbVD")
        .get();
      if (res.exists) {
        promo.value = res.data();
      }
    });

    const handleLogin = async () => {
      // if (!isPlatform("desktop")) {
      //   const loginAlert = await alertController.create({
      //     cssClass: "my-custom-class",
      //     header: "Already have an account?",
      //     // subHeader: "Log in now",
      //     message:
      //       "To continue with the web-based version of the app, click the Login button below. Otherwise, switch over to the app and log in there.",
      //     buttons: [
      //       {
      //         text: "Cancel",
      //         role: "cancel",
      //         cssClass: "secondary",
      //       },
      //       {
      //         text: "Login",
      //         handler: async () => {
      //           router.replace({ name: "SiteLogin" });
      //         },
      //       },
      //     ],
      //   });
      //   loginAlert.present();
      // } else {
      //   router.replace({ name: "SiteLogin" });
      // }
      router.replace({ name: "SiteLogin" });
    };

    const handleSelectPlan = async () => {
      priceId.value =
        process.env.NODE_ENV === "development"
          ? STRIPE_PRICE_39_TEST
          : STRIPE_PRICE_39_LIVE;

      // new user without an account - select plan and route to sign up page
      if (!user.value.uid) {
        router.push({ name: "SiteSignup" });
      } else if (user.subscription) {
        router.replace({ name: "Account" });
      } else {
        isLoading.value = true;
        loadingStart(20);
        await selectSubscription();
        loadingStop();
        isLoading.value = false;
      }
    };

    return {
      handleLogin,
      promo,
      isLoading,
      handleSelectPlan,
      checkboxOutline,
      calcCost,
      calcTime,
      calcRepairs,
      averageProfit,
      numLocations,
      averageMinutes,
      calcResult,
      calculateCost,
    };
  },
};
</script>

<style scoped>
.vertical-align {
  display: flex !important;
  align-items: center !important;
}

* {
  box-sizing: border-box;
}

/* Create three columns of equal width */
.columns {
  float: center;
  width: 100%;
}

/* Style the list */
.price {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

/* Add shadows on hover */
.price:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

/* Pricing header */
.price .header {
  background-color: #a0b419;
  color: white;
  font-size: 25px;
}

/* List items */
.price li {
  border-bottom: 1px solid #eee;
  padding: 20px;
  text-align: center;
}

/* Grey list item */
.price .grey {
  background-color: #eee;
  font-size: 20px;
}

/* The "Sign Up" button */
.signup-button {
  background-color: #e96432;
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

/* Change the width of the three columns to 100%
(to stack horizontally on small screens) */
@media only screen and (max-width: 600px) {
  .columns {
    width: 100%;
  }
}
</style>