import {
    ref
} from "vue";
import {
    geoFirestore,
    geoPoint
}
from "../firebase";
import useAccount from "@/composables/useAccount";
import toast from "../utilities/toast";

const {
    getBusinessInfo
} = useAccount();
const markers = ref([]);
const markersError = ref(null);

const getMarkers = () => {

    const markersLoad = async (lat, lng, radius) => {
        try {
            let tempList = [];
            const geocollection = geoFirestore.collection('locations');
            const query = geocollection.near({
                center: new geoPoint(lat, lng),
                radius: radius * 1.609344
            }).where("isActive", "==", true);

            let res = await query.get();
            const locationList = res.docs.map(async (doc) => {
                const data = doc.data();
                let servicesOffered = [];
                let locationTypeList = [];
                let appointmentOptionList = [];
                let paymentOptionList = [];
                if (data.services) {
                    servicesOffered = data.services.filter(s => s.isChecked === true);
                }
                if (data.locationTypes) {
                    locationTypeList = data.locationTypes.filter(s => s.isChecked === true);
                }
                if (data.paymentTypes) {
                    paymentOptionList = data.paymentTypes.filter(s => s.isChecked === true);
                }
                if (data.appointmentTypes) {
                    appointmentOptionList = data.appointmentTypes.filter(s => s.isChecked === true);
                }
                const companyInfo = await getBusinessInfo(data.companyId);
                return {
                    companyInfo,
                    title: data.title,
                    isActive: data.isActive,
                    address: data.address,
                    phone: data.phone,
                    email: data.email,
                    hours: data.hours,
                    services: servicesOffered,
                    locationTypes: locationTypeList,
                    paymentTypes: paymentOptionList,
                    appointmentTypes: appointmentOptionList,
                    lat: data.coordinates.latitude,
                    lng: data.coordinates.longitude,
                };
            });
            let finalList = Promise.all(locationList);
            (await finalList).forEach(location => {
                tempList.push(location);
            });
            markers.value = tempList;
        } catch (err) {
            console.log(err.message);
            markersError.value = "Unable to load markers.";
            toast(markersError.value);
        }
    }

    return {
        markers,
        markersError,
        markersLoad
    }
}

export default getMarkers;