<template>
  <ion-page>
    <ion-content id="wrapper">
      <div class="map" ref="mapDivRef"></div>

      <ion-grid>
        <ion-row>
          <ion-col sizeSm="8" offsetSm="2">
            <ion-searchbar mode="md" ref="searchInputRef"></ion-searchbar>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="handleMyLocation">
          <ion-spinner v-if="isLoading" name="dots"></ion-spinner>
          <ion-icon v-else :icon="fabIcon"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script>
import { GOOGLE_API_KEY } from "../../constants";
import { ref, onMounted, watch } from "vue";
import { Plugins } from "@capacitor/core";

import LocationInfo from "@/components/Map/LocationInfo";
import manageMap from "@/composables/manageMap";
import getMarkers from "@/composables/getMarkers";

import { locate } from "ionicons/icons";

import toast from "@/utilities/toast";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
  IonIcon,
  IonFab,
  IonFabButton,
  IonContent,
  IonPage,
  IonSearchbar,
  modalController,
} from "@ionic/vue";

export default {
  name: "GMap",

  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonSpinner,
    IonIcon,
    IonFab,
    IonFabButton,
    IonContent,
    IonPage,
    IonSearchbar,
  },

  props: {
    zoom: Number,
    canMoveMarker: Boolean,
    initialCenter: Object,
    radius: Number,
  },

  setup(props, context) {
    const { Geolocation } = Plugins;
    const {
      map,
      mapCenter,
      mapOrigin,
      mapZoom,
      mapRadius,
      mapUserMarker,
    } = manageMap();
    const { markers } = getMarkers();
    const fabIcon = ref(locate);

    const isLoading = ref(false);
    const search = ref(null);
    const mapDivRef = ref(null);
    const searchInputRef = ref(null);

    let nearbyLocationMarkers = [];

    watch(markers, () => {
      loadMapMarkers();
    });

    watch(mapCenter, (value) => {
      map.value.zoom = 17;
      map.value.panTo(value);
    });

    watch(mapZoom, (value) => {
      map.value.zoom = value;
      map.value.panTo(mapCenter.value);
    });

    watch(mapRadius, (value) => {
      if (value === 5) {
        mapZoom.value = 12;
      } else if (value === 10) {
        mapZoom.value = 11;
      } else if (value === 25) {
        mapZoom.value = 10;
      } else if (value === 50) {
        mapZoom.value = 9;
      }
      mapCenter.value = mapOrigin.value;
      map.value.setCenter(mapCenter.value);
      map.value.zoom = mapZoom.value;
      context.emit("refreshMarkers");
    });

    onMounted(() => {
      const googleMapScriptId = "google-map-script";
      const googleMapScriptExists = document.getElementById(googleMapScriptId);
      if (!googleMapScriptExists) {
        const googleMapScript = document.createElement("script");
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&callback=initMap&libraries=places&v=weekly&fields=formatted_address,geometry`;
        googleMapScript.id = googleMapScriptId;
        googleMapScript.defer = true;
        googleMapScript.async = true;
        document.head.appendChild(googleMapScript);
      } else {
        setTimeout(() => {
          window.initMap();
        }, 100);
      }
    });

    const clearMapMarkers = () => {
      if (nearbyLocationMarkers.length > 0) {
        for (let i = 0; i < nearbyLocationMarkers.length; i++) {
          let marker = nearbyLocationMarkers[i];
          marker.setMap(null);
        }
        nearbyLocationMarkers = [];
      }
    };

    const loadMapMarkers = () => {
      clearMapMarkers();
      markers.value.forEach((markerInfo) => {
        let newMarker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            markerInfo.lat,
            markerInfo.lng
          ),
          icon: "assets/marker.svg",
          map: map.value,
          title: markerInfo.title,
          animation: window.google.maps.Animation.DROP,
        });
        newMarker.addListener("click", async () => {
          const modal = await modalController.create({
            component: LocationInfo,
            cssClass: "my-custom-class",
            componentProps: {
              info: markerInfo,
            },
          });
          return modal.present();
        });
        nearbyLocationMarkers.push(newMarker);
      });
    };

    const createMarker = (position) => {
      let latLng = new window.google.maps.LatLng(position);
      mapUserMarker.value = new window.google.maps.Marker({
        position: latLng,
        map: map.value,
        draggable: props.canMoveMarker,
        icon: props.canMoveMarker ? null : "assets/target.png",
      });
      if (props.canMoveMarker) {
        context.emit("markerPosition", latLng);
      }
    };

    window.initMap = async () => {
      map.value = new window.google.maps.Map(mapDivRef.value, {
        mapTypeId: "roadmap",
        zoom: mapZoom.value,
        disableDefaultUI: true,
        center: props.initialCenter ?? mapOrigin.value,
      });

      createMarker(props.initialCenter ?? mapOrigin.value);
      search.value = new window.google.maps.places.Autocomplete(
        await searchInputRef?.value?.$el.getInputElement(),
        {
          types: ["geocode"],
        }
      );

      window.google.maps.event.addListener(
        search.value,
        "place_changed",
        () => {
          let near_place = search.value.getPlace();
          if (near_place.geometry) {
            const position = {
              lat: near_place.geometry.location.lat(),
              lng: near_place.geometry.location.lng(),
            };
            if (
              mapOrigin.value?.lat === position.lat &&
              mapOrigin.value?.lng === position.lng
            ) {
              map.value.panTo(mapOrigin.value);
            } else {
              clearMapMarkers();
              mapOrigin.value = position;
              mapCenter.value = mapOrigin.value;
              let latLng = new window.google.maps.LatLng({
                lat: mapCenter.value.lat,
                lng: mapCenter.value.lng,
              });
              mapUserMarker.value.setPosition(latLng);
              map.value.zoom = mapZoom.value;
              map.value.panTo(latLng);
              context.emit("markerPosition", latLng);
              context.emit("refreshMarkers");
            }
          } else {
            toast("Location not found.");
          }
        }
      );

      if (props.canMoveMarker) {
        window.google.maps.event.addListener(map.value, "click", (event) => {
          let latLng = new window.google.maps.LatLng({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          });
          mapUserMarker.value.setPosition(latLng);
          context.emit("markerPosition", latLng);
        });

        window.google.maps.event.addListener(
          mapUserMarker.value,
          "dragend",
          (event) => {
            let latLng = event.latLng;
            context.emit("markerPosition", latLng);
          }
        );
      }
    };

    const handleMyLocation = async () => {
      if (!isLoading.value) {
        isLoading.value = true;
        await Geolocation.getCurrentPosition()
          .then((pos) => {
            clearMapMarkers();
            const position = {
              lat: pos.coords.latitude,
              lng: pos.coords.longitude,
            };
            mapOrigin.value = position;
            mapCenter.value = mapOrigin.value;
            let latLng = new window.google.maps.LatLng({
              lat: mapCenter.value.lat,
              lng: mapCenter.value.lng,
            });
            mapUserMarker.value.setPosition(latLng);
            map.value.panTo(latLng);
            context.emit("markerPosition", latLng);
            context.emit("refreshMarkers");
          })
          .catch(async (err) => {
            console.log(err.message);
            await toast(
              "Unable to obtain your current location. Please check the location settings on your device."
            );
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
    };

    return { mapDivRef, searchInputRef, fabIcon, handleMyLocation, isLoading };
  },
};
</script>

<style scoped>
#wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.map {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: azure;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.gm-style .gm-style-iw {
  color: red;
  font-size: 16px;
  font-weight: bold;
  font-family: sans-serif;
  text-transform: uppercase;
}
</style>