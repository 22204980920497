<template>
  <ion-content>
    <ion-list lines="none">
      <ion-item :disabled="!user.value.canManageLocations" button @click="handleEdit">
        <ion-label>Edit</ion-label>
      </ion-item>
      <ion-item :disabled="!user.value.canManageLocations" @click="presentDeleteConfirm">
        <ion-label>Delete</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import { useRouter } from "vue-router";
import {
  IonLabel,
  IonItem,
  IonList,
  IonContent,
  alertController,
  popoverController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import getUser from "@/composables/getUser";
import useLocation from "@/composables/useLocation";
import loading from "@/utilities/loading";

export default defineComponent({
  name: "LocationDetailsMenu",

  components: { IonLabel, IonItem, IonList, IonContent },

  props: ["id"],

  setup(props) {
    const { loadingStart, loadingStop } = loading();
    const router = useRouter();
    const { deleteDoc, locationError } = useLocation();
    const { user } = getUser();

    const handleEdit = () => {
      if (props.id) {
        popoverController.dismiss();
        router.push({ name: "LocationEdit", params: { id: props.id } });
      }
    };

    const presentDeleteConfirm = async () => {
      popoverController.dismiss();
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Are you sure?",
        message: "This action can not be undone.",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Delete",
            handler: async () => {
              loadingStart(5);
              await deleteDoc(props.id);
              loadingStop();
              if (!locationError.value) {
                router.replace("/locations");
              }
            },
          },
        ],
      });
      return alert.present();
    };
    return { handleEdit, presentDeleteConfirm, user };
  },
});
</script>