<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>Locations within {{ mapRadius }} miles</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="modalController.dismiss()">Close</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list v-if="nearbyResults.length > 0">
      <ion-item
        v-for="location in nearbyResults"
        :key="location.id"
        lines="full"
        @click="locationSelected(location)"
      >
        <ion-label>
          <h2>{{ location.title }}</h2>
          <p>{{ location.address }}</p>
        </ion-label>
        <ion-icon
          slot="end"
          color="primary"
          :icon="informationOutline"
          @click="openLocationInfo(location)"
        ></ion-icon>
        <ion-icon
          slot="end"
          color="primary"
          :icon="navigateOutline"
          @click="navigateToLocation(location)"
        ></ion-icon>
      </ion-item>
    </ion-list>
    <ion-list v-else>
      <ion-item>
        <ion-label>No locations found.</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonButton,
  IonButtons,
  IonLabel,
  IonIcon,
  IonItem,
  IonList,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";

import { navigateOutline, informationOutline } from "ionicons/icons";
import { defineComponent, ref } from "vue";
import { Plugins } from "@capacitor/core";

import LocationInfo from "@/components/Map/LocationInfo";
import manageMap from "@/composables/manageMap";

export default defineComponent({
  name: "ResultsModal",
  components: {
    IonButton,
    IonButtons,
    IonLabel,
    IonIcon,
    IonItem,
    IonList,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
  },
  props: ["results"],
  setup(props) {
    const { Browser } = Plugins;
    const nearbyResults = ref(props.results);
    const { mapRadius } = manageMap();

    const locationSelected = (data) => {
      modalController.dismiss(data);
    };

    const openLocationInfo = async (location) => {
      const modal = await modalController.create({
        component: LocationInfo,
        cssClass: "my-custom-class",
        componentProps: {
          info: location,
        },
      });
      return modal.present();
    };

    const navigateToLocation = async (location) => {
      await Browser.open({
        url: `https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}&dir_action=navigate`,
      });
    };

    return {
      openLocationInfo,
      informationOutline,
      mapRadius,
      navigateOutline,
      locationSelected,
      modalController,
      nearbyResults,
      navigateToLocation,
    };
  },
});
</script>