import {
    ref
} from "vue";
import {
    projectAuth,
    projectFirestore,
} from "../firebase";
import toast from "../utilities/toast"

const accountError = ref(null);

const changePassword = async (password) => {
    accountError.value = null;
    try {
        projectAuth.currentUser.updatePassword(password).then(() => {
            accountError.value = null;
            return true;
        }).catch(err => {
            accountError.value = err.message;
            return false;
        });

    } catch (err) {
        console.log(err.message);
        accountError.value = "Unable to change your password.";
        await toast(accountError.value);
        return false;
    }
}

const resetPassword = async (email) => {
    accountError.value = null;
    try {
        const res = await projectAuth.sendPasswordResetEmail(email);
        accountError.value = null;
        return res;
    } catch (err) {
        console.log(err.message);
        accountError.value = 'Unable to reset your password.';
        await toast(err.message);
    }
}

const getBusinessInfo = async (id) => {
    accountError.value = null
    try {
        const res = await projectFirestore.collection('customers').doc(id).get();
        if (res.exists) {
            return res.data();
        }
        return null;
    } catch (err) {
        console.log(err.message);
        accountError.value = "Unable to get company name.";
        toast(accountError.value);
        return null;
    }
}

const updateBusinessInfo = async (id, info) => {
    accountError.value = null
    try {
        const res = await projectFirestore.collection('customers').doc(id).update({
            companyName: info.companyName,
            companyBio: info.companyBio
        });
        return res;
    } catch (err) {
        console.log(err.message);
        accountError.value = "Unable to update business information.";
        await toast(accountError.value);
    }
}

const updateCompanyCert = async (id, companyCertName, companyCertUrl) => {
    accountError.value = null
    try {
        const res = await projectFirestore.collection('customers').doc(id).update({
            companyCertName: companyCertName,
            companyCertUrl: companyCertUrl,
        });
        return res;
    } catch (err) {
        console.log(err.message);
        accountError.value = "Unable to update company certification info.";
        await toast(accountError.value);
    }
}

const useAccount = () => {
    return {
        accountError,
        changePassword,
        resetPassword,
        getBusinessInfo,
        updateBusinessInfo,
        updateCompanyCert,
    }
}

export default useAccount;