<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end">
        <!-- <ion-button @click="modalController.dismiss()">Cancel</ion-button> -->
        <ion-button color="primary" @click="closeModal">Save</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <g-map
      :zoom="initialZoom"
      :canMoveMarker="true"
      :initialCenter="initialCoords"
      @markerPosition="setPosition"
    />
  </ion-content>
</template>

<script>
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import GMap from "@/components/Map/GMap";

export default defineComponent({
  name: "Modal",

  props: {
    title: { type: String, default: "Super Modal" },
    coords: { type: Object },
    zoom: { type: Number, default: 1 },
  },

  components: {
    IonButton,
    IonButtons,
    GMap,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
  },

  setup(props) {
    const initialCoords = ref(props.coords);
    const initialZoom = ref(props.zoom);
    let position;
    const setPosition = (data) => {
      position = {
        lat: data.lat(),
        lng: data.lng(),
      };
    };

    const closeModal = () => {
      modalController.dismiss(position);
    };

    return {
      setPosition,
      initialCoords,
      initialZoom,
      closeModal,
      modalController,
    };
  },
});
</script>