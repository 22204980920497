<template>
  <ion-app>
    <IonSplitPane content-id="main-content">
      <ion-menu
        content-id="main-content"
        type="overlay"
        ref="sideMenu"
        v-if="$router.currentRoute.name !== 'SiteHome'"
      >
        <ion-content>
          <navigation @menuItemSelected="closeMenu" />
        </ion-content>
      </ion-menu>
      <keep-alive>
        <ion-router-outlet id="main-content"></ion-router-outlet>
      </keep-alive>
    </IonSplitPane>
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonContent,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/vue";
import { useRouter } from "vue-router";
import { Storage } from "@ionic/storage";
import { defineComponent, onMounted, ref } from "vue";
import Navigation from "@/components/Site/Navigation";

export default defineComponent({
  name: "App",
  components: {
    Navigation,
    IonApp,
    IonContent,
    IonMenu,
    IonRouterOutlet,
    IonSplitPane,
  },

  setup() {
    const router = useRouter();
    const store = new Storage();
    const sideMenu = ref(null);

    onMounted(async () => {
      await store.create();
      const onboard = await store.get("onboard");
      if (!onboard) {
        router.replace({ name: "SiteSlides" });
      }
    });

    const closeMenu = async () => {
      await sideMenu?.value?.$el?.close();
    };

    return { closeMenu, sideMenu };
  },
});
</script>

<style scoped>
ion-split-pane {
  --border: 1px solid #dddddd;
}
</style>