import {
    ref
} from "vue";
import {
    projectFirestore
}
from "../firebase";
import getUser from "../composables/getUser";
import toast from "../utilities/toast";

const locations = ref([]);
const activeLocationsCount = ref(0);
const locationsError = ref(null);
const hideInactive = ref(false);
const sortBy = ref(['title', 'asc'])
const unsubscribe = ref(null);

const getLocations = () => {
    const {
        user
    } = getUser();

    const loadLocations = async () => {
        let userId = user.value.uid;
        if (user.value.linkedTo) {
            userId = user.value.linkedTo;
        }
        try {
            unsubscribe.value = projectFirestore
                .collection('locations')
                .where("companyId", "==", userId)
                .orderBy(sortBy.value[0], sortBy.value[1])
                .onSnapshot((snap) => {
                    activeLocationsCount.value = 0;
                    let docs = snap.docs.map(doc => {
                        const data = doc.data();
                        if (data.isActive) {
                            activeLocationsCount.value += 1;
                        }
                        return {
                            ...data,
                            id: doc.id
                        }
                    });
                    locations.value = docs;
                });
        } catch (err) {
            console.log(err.message);
            locationsError.value = "Unable to load locations.";
            await toast(locationsError.value);
        }
    }

    const unloadLocations = async () => {
        if (unsubscribe.value) {
            await unsubscribe.value();
            unsubscribe.value = null;
        }
    }

    const setHideInactive = (val) => {
        hideInactive.value = val;
    }

    const setSort = (val) => {
        sortBy.value = val;
        loadLocations();
    }

    return {
        locations,
        hideInactive,
        sortBy,
        activeLocationsCount,
        locationsError,
        setHideInactive,
        setSort,
        loadLocations,
        unloadLocations
    }
}

export default getLocations;