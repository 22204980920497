import {
    loadingController,
} from "@ionic/vue";


const loading = () => {
    let load = null;

    const loadingStart = async (duration) => {
        load = await loadingController
            .create({
                cssClass: 'my-custom-class',
                message: 'Please wait...',
                duration: duration * 1000,
            });

        setTimeout(() => {
            load.dismiss()
        }, 30000);

        await load.present();
    }

    const loadingStop = async () => {
        if (load) {
            load.dismiss();
        }
    }

    return {
        loadingStart,
        loadingStop
    }
}

export default loading;