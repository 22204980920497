import {
    reactive
} from "vue";
import {
    projectAuth,
    projectFirestore
} from "../firebase";
import getSubscription from "./getSubscription";

const user = reactive({
    value: projectAuth.currentUser,
    subscription: null
});

projectAuth.onAuthStateChanged(_user => {
    user.value = {
        ..._user
    };
    if (user.value.uid) {
        setupUser(user.value.uid);
    }
});

const setupUser = async (uid) => {
    const {
        loadSubscription
    } = getSubscription();
    try {
        const res = await projectFirestore.collection('customers').doc(uid).get();
        const data = res.data();
        if (data) {
            user.value.linkedTo = data.linkedTo;
            user.value.canManageLocations = data.linkedTo ? data.isActive : true;
            await loadSubscription();
        }
    } catch (err) {
        console.log(err.message);
    }
}

const getUser = () => {
    return {
        user,
        setupUser
    }
}

export default getUser;