import {
    ref
} from "vue";
import {
    projectAuth,
    secondaryAuth,
    projectFirestore,
} from "../firebase";
import toast from "../utilities/toast";

const manageUsersError = ref(null);
const users = ref([]);

const manageUsers = () => {
    const addUser = async (email, password) => {
        manageUsersError.value = null;
        try {
            const res = await secondaryAuth.createUserWithEmailAndPassword(email, password);
            if (res.user) {
                await projectFirestore.collection('customers').doc(res.user.uid).set({
                    linkedTo: projectAuth.currentUser.uid,
                    isActive: true,
                    email
                });
            }
            toast(`${email} created successfully.`);
        } catch (err) {
            console.log(err.message);
            manageUsersError.value = "Unable to create sub-account.";
            toast(manageUsersError.value);
        }
    }

    const getUsers = async () => {
        try {
            projectFirestore.collection('customers').where("linkedTo", "==", projectAuth.currentUser.uid).orderBy('email').onSnapshot((snap) => {
                let docs = snap.docs.map(doc => {
                    const data = doc.data();
                    return {
                        ...data,
                        id: doc.id
                    }
                });
                users.value = docs;
            });
        } catch (err) {
            console.log(err.message);
            manageUsersError.value = "Unable to load sub-accounts.";
            toast(manageUsersError.value);
        }
    }

    const toggleUser = async (id, value) => {
        manageUsersError.value = null;
        try {
            await projectFirestore.collection('customers').doc(id).update({
                isActive: value
            });
            manageUsersError.value = null;
        } catch (err) {
            console.log(err.message);
            manageUsersError.value = "Unable to edit sub-account.";
            await toast(manageUsersError.value);
        }
    }

    const deleteUser = async (id) => {
        manageUsersError.value = null;
        try {
            await projectFirestore.collection('customers').doc(id).delete();
            manageUsersError.value = null;
        } catch (err) {
            console.log(err.message);
            manageUsersError.value = "Unable to delete sub-account";
            await toast(manageUsersError.value);
        }
    }

    return {
        manageUsersError,
        users,
        addUser,
        getUsers,
        toggleUser,
        deleteUser,
    }
}



export default manageUsers;