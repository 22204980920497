import firebase from "firebase/app"
import "firebase/firestore"
import {
    projectFirestore,
} from "../firebase";
import getUser from "./getUser";
import {
    ref
} from "vue";
import toast from "@/utilities/toast";
import getLocations from "./getLocations";
import useLocation from "./useLocation";

const getSubscription = () => {
    const {
        activeLocationsCount,
        locations,
    } = getLocations();
    const {
        user
    } = getUser();
    const {
        updateDoc
    } = useLocation();

    // const subscription = reactive({});
    const subscriptionError = ref(null);

    const checkActiveVersusAllowed = (allowed) => {
        if (activeLocationsCount.value > allowed) {
            locations.value.forEach(async location => {
                await updateDoc(location.id, {
                    isActive: false
                });
                toast("Your plan has changed. You can now activate your location(s).");
            });
        }
    }

    const loadSubscription = async () => {
        if (user.value) {
            let userId = user.value.uid;
            if (user.value.linkedTo) {
                userId = user.value.linkedTo;
            }
            const res = await projectFirestore.collection('customers')
                .doc(userId)
                .collection('subscriptions')
                .where('status', 'in', ['trialing', 'active'])
                .get();
            if (!res.empty) {
                user.subscription = {
                    ...res.docs[0].data()
                };
                if (activeLocationsCount.value > 0) {
                    checkActiveVersusAllowed(user.subscription.quantity);
                }
            }
        }
    }

    const portal = async () => {
        subscriptionError.value = "";
        try {
            const functionRef = firebase
                .app()
                .functions('us-central1')
                .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
            const {
                data
            } = await functionRef({
                returnUrl: process.env.NODE_ENV === "development" ? window.location.origin : "https://chipfix.app/account"
            });
            window.location.assign(data.url);
        } catch (err) {
            subscriptionError.value = "Unable to load subscription.";
            toast(subscriptionError.value);
        }
    }
    return {
        subscriptionError,
        loadSubscription,
        portal,
    }
}

export default getSubscription;