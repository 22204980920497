import {
    ref
} from "vue";
import {
    projectFirestore,
    projectStorage,
    geoFirestore,
}
from "../firebase";
import getUser from "../composables/getUser";
import {
    toastController,
} from "@ionic/vue";
import toast from "../utilities/toast";

const useLocation = () => {
    const {
        user
    } = getUser();

    const locationError = ref(null);

    const addDoc = async (doc) => {
        locationError.value = null;
        let userId = user.value.uid;
        if (user.value.linkedTo) {
            userId = user.value.linkedTo;
        }
        try {
            const res = await geoFirestore.collection('locations').add({
                ...doc,
                companyId: userId
            });
            return res;
        } catch (err) {
            console.log(err.message);
            locationError.value = "Unable to add the document.";

            const toast = await toastController.create({
                message: locationError.value,
                duration: 3000,
                buttons: [{
                    text: 'OK',
                    role: 'cancel',
                }]
            });
            toast.present();

            return null;
        }
    }

    const toggleActive = async (id, value) => {
        locationError.value = null;
        try {
            await geoFirestore.collection('locations').doc(id).update({
                isActive: value
            });
            locationError.value = null;
        } catch (err) {
            console.log(err.message);
            locationError.value = "Unable to update the location.";
            await toast(locationError.value);
        }
    }

    const updateDoc = async (id, doc) => {
        locationError.value = null;
        try {
            await geoFirestore.collection('locations').doc(id).update(doc);
        } catch (err) {
            console.log(err.message);
            locationError.value = "Unable to update the location.";
            await toast(locationError.value);
        }
    }

    const deleteDoc = async (id) => {
        locationError.value = null;
        let userId = user.value.uid;
        if (user.value.linkedTo) {
            userId = user.value.linkedTo;
        }
        try {
            try {
                await projectStorage.ref(`location_maps/${userId}/${id}.png`).delete();
            } catch (err) {
                console.log(err.message);
                locationError.value = "An error occured while attempting to delete this location. Please try again later.";
                await toast(locationError.value);
            }
            await projectFirestore.collection('locations').doc(id).delete();
        } catch (err) {
            console.log(err.message);
            locationError.value = "An error occured while attempting to delete this location. Please try again later.";
            await toast(locationError.value);
        }
    }

    return {
        locationError,
        addDoc,
        toggleActive,
        updateDoc,
        deleteDoc,
    }
}

export default useLocation;