<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ info.companyInfo.companyName }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="modalController.dismiss()">Close</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-card>
      <ion-card-header>
        <ion-card-title>{{ info.title }}</ion-card-title>
        <ion-card-subtitle>{{ info.address }}</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        <ion-item v-if="info.phone" lines="none" button :href="`tel:${info.phone}`">
          <ion-icon :icon="callOutline" slot="start"></ion-icon>
          <ion-label>{{ info.phone }}</ion-label>
        </ion-item>
        <ion-item v-if="info.email" lines="none" button :href="`mailto:${info.email}`">
          <ion-icon :icon="mailOutline" slot="start"></ion-icon>
          <ion-label>{{ info.email }}</ion-label>
        </ion-item>
        <ion-item
          v-if="info.companyInfo.isCertified"
          lines="none"
          button
          @click="handleConfirmedAlert()"
        >
          <ion-icon :icon="ribbonOutline" slot="start"></ion-icon>
          <!-- <ion-img :src="badgeIconPath" slot="start" style="width:24px;height:24px;"></ion-img> -->
          <ion-label>Certified Technician</ion-label>
        </ion-item>
        <ion-button
          expand="block"
          target="_blank"
          :href="`https://www.google.com/maps/dir/?api=1&destination=${info.lat},${info.lng}&dir_action=navigate`"
        >Navigate</ion-button>
      </ion-card-content>
    </ion-card>

    <ion-card v-if="info.locationTypes?.length">
      <ion-card-header>
        <ion-card-title>Location Types</ion-card-title>
      </ion-card-header>
      <ion-list lines="none">
        <ion-item v-for="entry in info.locationTypes" :key="entry.label">
          <ion-icon slot="start" color="success" :icon="checkboxOutline"></ion-icon>
          <ion-label
            class="ion-text-wrap"
          >{{ entry.label !== 'Other' ? entry.label : entry.value !== '' ? entry.value : 'Other' }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-card>

    <ion-card v-if="info.appointmentTypes?.length">
      <ion-card-header>
        <ion-card-title>Appointment Options</ion-card-title>
      </ion-card-header>
      <ion-list lines="none">
        <ion-item v-for="entry in info.appointmentTypes" :key="entry.label">
          <ion-icon slot="start" color="success" :icon="checkboxOutline"></ion-icon>
          <ion-label
            class="ion-text-wrap"
          >{{ entry.label !== 'Other' ? entry.label : entry.value !== '' ? entry.value : 'Other' }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-card>

    <ion-card v-if="info.services?.length">
      <ion-card-header>
        <ion-card-title>Services Offered</ion-card-title>
      </ion-card-header>
      <ion-list lines="none">
        <ion-item v-for="entry in info.services" :key="entry.label">
          <ion-icon slot="start" color="success" :icon="checkboxOutline"></ion-icon>
          <ion-label
            class="ion-text-wrap"
          >{{ entry.label !== 'Other' ? entry.label : entry.value !== '' ? entry.value : 'Other' }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-card>

    <ion-card v-if="info.paymentTypes?.length">
      <ion-card-header>
        <ion-card-title>Payment Types Accepted</ion-card-title>
      </ion-card-header>
      <ion-list lines="none">
        <ion-item v-for="entry in info.paymentTypes" :key="entry.label">
          <ion-icon slot="start" color="success" :icon="checkboxOutline"></ion-icon>
          <ion-label
            class="ion-text-wrap"
          >{{ entry.label !== 'Other' ? entry.label : entry.value !== '' ? entry.value : 'Other' }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-card>

    <ion-card v-if="info.hours?.length">
      <ion-card-header>
        <ion-card-title>Hours of Operation</ion-card-title>
      </ion-card-header>
      <ion-list>
        <ion-item v-for="day in info.hours" :key="day.label">
          <ion-label>{{ day.label }}</ion-label>
          <ion-datetime v-if="day.active" display-format="h:mm a" :value="day.open" readonly></ion-datetime>
          <ion-datetime v-if="day.active" display-format="h:mm a" :value="day.close" readonly></ion-datetime>
          <ion-label class="ion-text-end" v-else>Closed</ion-label>
        </ion-item>
      </ion-list>
    </ion-card>

    <ion-card v-if="info.companyInfo.companyBio">
      <ion-card-header>
        <ion-card-title>Company Bio</ion-card-title>
      </ion-card-header>
      <ion-card-content>{{ info.companyInfo.companyBio }}</ion-card-content>
    </ion-card>
  </ion-content>
</template>

<script>
import { Plugins } from "@capacitor/core";
import getUser from "@/composables/getUser";
import {
  checkboxOutline,
  locationOutline,
  callOutline,
  mailOutline,
  ribbonOutline,
} from "ionicons/icons";
import {
  IonTitle,
  IonDatetime,
  IonIcon,
  IonCardTitle,
  IonCardSubtitle,
  IonCardHeader,
  IonLabel,
  IonItem,
  IonList,
  IonCardContent,
  IonCard,
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  modalController,
  alertController,
} from "@ionic/vue";
export default {
  props: ["info"],

  components: {
    IonTitle,
    IonDatetime,
    IonIcon,
    IonCardTitle,
    IonCardSubtitle,
    IonCardHeader,
    IonLabel,
    IonItem,
    IonList,
    IonCardContent,
    IonCard,
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
  },

  setup() {
    const { Browser } = Plugins;
    const { user } = getUser();

    const badgePath = "assets/badge.png";

    const navigateToLocation = async (location) => {
      await Browser.open({
        url: `https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}&dir_action=navigate`,
      });
    };

    const handleConfirmedAlert = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        message: `<img src="${badgePath}" class="badge"><br><br><span style="text-align:center;">ChipFix has confirmed this location to be owned and operated by a certified chip repair company.</span>`,
        buttons: ["OK"],
      });
      return alert.present();
    };

    return {
      navigateToLocation,
      ribbonOutline,
      modalController,
      checkboxOutline,
      locationOutline,
      callOutline,
      mailOutline,
      user,
      handleConfirmedAlert,
    };
  },
};
</script>

<style>
.badge {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>