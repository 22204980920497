import {
  createRouter,
  createWebHistory
} from '@ionic/vue-router';

import getUser from "../composables/getUser";
import getLocation from "../composables/getLocation";

import HomeMap from "@/views/Home/Map.vue";
import LocationList from "@/views/Location/List.vue";
import LocationDetails from "@/views/Location/Details.vue";
import LocationEdit from "@/views/Location/Edit.vue";
import LocationNew from "@/views/Location/New.vue";
import Account from "@/views/Account/Account.vue";
import AccountUserList from "@/views/Account/UserList.vue";
import AccountUserNew from "@/views/Account/UserNew.vue";
import SiteHelp from "@/views/Site/Help.vue";
import SiteLogin from "@/views/Site/Login.vue";
import SiteSignup from "@/views/Site/Signup.vue";
import SiteSelectPlan from "@/views/Site/SelectPlan.vue";
import SiteTerms from "@/views/Site/Terms.vue";
import SitePrivacy from "@/views/Site/Privacy.vue";
import Slides from "@/views/Site/Slides.vue";

const {
  user,
} = getUser();



const {
  userOwnsLocation
} = getLocation();

const requiresAuth = (to, from, next) => {
  if (!user.value.uid) {
    next({
      name: 'SiteLogin'
    });
  } else {
    next();
  }
}

const requiresNoAuth = (to, from, next) => {
  if (!user.value.uid) {
    next();
  } else {
    next({
      name: 'Account'
    });
  }
}

const locationGuard = (to, from, next) => {
  // if user is not logged in, redirect to site login
  if (!user.value.uid) {
    next({
      name: "SiteLogin"
    });
  } else {
    // user is logged in - see if they have a subscription
    if (user.subscription) {
      next();
    } else {
      next({
        name: "AccountPlans"
      });
    }
  }
}

const locationDetailsGuard = async (to, from, next) => {
  let locationId = to.params.id;
  // if user is not logged in, redirect to site login
  if (!user.value.uid) {
    next({
      name: "SiteLogin"
    });
  } else {
    const isOwner = await userOwnsLocation(locationId);
    if (locationId && isOwner) {
      next();
    } else {
      next({
        name: "Account"
      });
    }
  }
}

const manageUsersGuard = (to, from, next) => {
  if (!user.value.uid) {
    next({
      name: 'SiteLogin'
    });
  } else {
    if (!user.subscription) {
      next({
        name: 'AccountPlans'
      });
    } else {
      next();
    }
  }
}

const planGuard = async (to, from, next) => {
  // if user is not logged in, redirect to site login
  if (!user.value.uid) {
    next();
  } else {
    // user is logged in - send sub accounts to account page
    if (user.value.linkedTo) {
      next({
        name: "Account"
      });
      // see if the user already has a subscription
    } else if (from.path === "/" || user.subscription) {
      next({
        name: "Account"
      });
    } else {
      next();
    }
  }
}

const routes = [{
    path: '/',
    name: 'SiteHome',
    component: HomeMap,
  },
  {
    path: '/welcome',
    name: 'SiteSlides',
    component: Slides,
  },
  {
    path: '/help',
    name: 'SiteHelp',
    component: SiteHelp,
  },
  {
    path: '/login',
    name: 'SiteLogin',
    component: SiteLogin,
    beforeEnter: requiresNoAuth,
  },
  {
    path: '/signup',
    name: 'SiteSignup',
    component: SiteSignup,
    beforeEnter: requiresNoAuth,
  },
  {
    path: '/terms',
    name: 'SiteTerms',
    component: SiteTerms,
  },
  {
    path: '/select-plan',
    name: 'SiteSelectPlan',
    component: SiteSelectPlan,
    beforeEnter: planGuard,
  },
  {
    path: '/privacy',
    name: 'SitePrivacy',
    component: SitePrivacy,
  },
  {
    path: '/locations',
    name: 'LocationList',
    component: LocationList,
    beforeEnter: locationGuard,
  },
  {
    path: '/locations/new',
    name: 'LocationNew',
    component: LocationNew,
    beforeEnter: locationGuard,
  },
  {
    path: '/locations/:id',
    name: 'LocationDetails',
    component: LocationDetails,
    beforeEnter: locationDetailsGuard,
    props: true,
  },
  {
    path: '/locations/:id/edit',
    name: 'LocationEdit',
    component: LocationEdit,
    beforeEnter: locationDetailsGuard,
    props: true,
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    beforeEnter: requiresAuth,
  },
  {
    path: '/account/users',
    name: 'AccountUserList',
    component: AccountUserList,
    beforeEnter: manageUsersGuard,
  },
  {
    path: '/account/users/new',
    name: 'AccountUserNew',
    component: AccountUserNew,
    beforeEnter: manageUsersGuard,
  },
  {
    path: '/account/plans',
    name: 'AccountPlans',
    component: SiteSelectPlan,
    beforeEnter: planGuard,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: {
      name: 'SiteHome'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;