import {
    ref
} from "vue";
import axios from "axios";
import toast from "../utilities/toast";
import {
    GOOGLE_API_KEY
} from "../constants";

const geocodeError = ref(null);

const getAddressFromCoords = async (coords) => {
    geocodeError.value = null;
    try {
        const res = await axios
            .get("https://maps.googleapis.com/maps/api/geocode/json", {
                params: {
                    latlng: `${coords.lat},${coords.lng}`,
                    fields: "formatted_address",
                    key: GOOGLE_API_KEY,
                },
            });
        const formatted_address = res.data.results[0]['formatted_address']
        return formatted_address.replace(", USA", "");
    } catch (err) {
        console.log(err.message);
        geocodeError.value = "Unable to find address using coordinates.";
        toast(geocodeError.value);
    }
}

const getCoordsFromAddress = async (address) => {
    geocodeError.value = null;
    try {
        const res = await axios
            .get("https://maps.googleapis.com/maps/api/geocode/json", {
                params: {
                    address: address,
                    fields: "geometry",
                    key: GOOGLE_API_KEY,
                },
            });
        return res.data.results[0]['geometry']['location'];
    } catch (err) {
        console.log(err.message);
        geocodeError.value = 'Unable to find coordinates for that address.';
        toast(geocodeError.value);
    }
}

const useGeocode = () => {
    return {
        geocodeError,
        getAddressFromCoords,
        getCoordsFromAddress,
    }
}

export default useGeocode;