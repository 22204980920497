import {
    ref
} from "vue";
import {
    projectAuth
} from "../firebase";
import toast from "../utilities/toast";
import getUser from "./getUser";

const error = ref(null);
const {
    user
} = getUser();

const logout = async () => {
    error.value = null;
    try {
        await projectAuth.signOut()
        user.value.isSubAccount = false;
        user.value.linkedTo = null;
        user.value.canManageLocations = true;
        user.subscription = null;
    } catch (err) {
        console.log(err.message);
        error.value = "Error signing out.";
        toast(error.value);
    }
}

const useLogout = () => {
    return {
        logout,
        error
    }
}

export default useLogout;