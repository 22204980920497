<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Create an Account</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col sizeSm="6" offsetSm="3">
            <ion-card>
              <ion-card-content>
                <form @submit.prevent="checkForm">
                  <ion-list>
                    <ion-row>
                      <ion-col>
                        <ion-item>
                          <ion-label position="floating">Email</ion-label>
                          <ion-input type="email" v-model="email" required></ion-input>
                        </ion-item>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col>
                        <ion-item>
                          <ion-label position="floating">Password</ion-label>
                          <ion-input
                            :type="showPassword ? 'text' : 'password'"
                            v-model="password"
                            required
                          ></ion-input>
                          <ion-icon
                            :icon="showPassword ? eyeOutline : eyeOffOutline"
                            @click="toggleShowPassword"
                            slot="end"
                          ></ion-icon>
                        </ion-item>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col>
                        <ion-item>
                          <ion-label position="floating">Confirm Password</ion-label>
                          <ion-input
                            :type="showPassword ? 'text' : 'password'"
                            v-model="confirmPassword"
                            required
                          ></ion-input>
                        </ion-item>
                      </ion-col>
                    </ion-row>
                  </ion-list>
                  <ion-button
                    @click="checkForm"
                    color="primary"
                    size="large"
                    expand="block"
                  >Continue</ion-button>
                </form>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import loading from "@/utilities/loading";
import toast from "@/utilities/toast";

import { eyeOutline, eyeOffOutline } from "ionicons/icons";

import useRegister from "@/composables/useRegister";
import useSubscriptions from "@/composables/useSubscriptions";

import {
  IonIcon,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonList,
  IonButton,
  IonItem,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

export default {
  name: "SignupView",

  components: {
    IonIcon,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonInput,
    IonList,
    IonButton,
    IonItem,
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
  },

  setup() {
    const router = useRouter();
    const { loadingStart, loadingStop } = loading();
    const { selectSubscription, priceId } = useSubscriptions();
    const { register, registerError } = useRegister();

    const company = ref("");
    const email = ref("");
    const password = ref("");
    const confirmPassword = ref("");
    const showPassword = ref(false);

    const isLoading = ref(false);

    const checkForm = () => {
      if (!email.value) {
        return toast("Please enter an email address.");
      } else if (!password.value) {
        return toast("Please enter a password.");
      } else if (password.value !== confirmPassword.value) {
        return toast("Passwords do not match.");
      } else if (password.value.length < 6) {
        return toast("Password must be at least 6 characters long.");
      } else if (email.value && password.value) {
        return handleRegister();
      }
    };

    const toggleShowPassword = () => {
      showPassword.value = !showPassword.value;
    };

    const handleRegister = async () => {
      if (priceId.value) {
        loadingStart(5);
        isLoading.value = true;
        const res = await register(email.value, password.value);
        if (!registerError.value && res) {
          router.replace({ name: "SiteHome" });
          loadingStart(20);
          await selectSubscription();
        }
        loadingStop();
      } else {
        router.replace({ name: "SiteSelectPlan" });
      }
    };

    return {
      isLoading,
      company,
      email,
      password,
      confirmPassword,
      toggleShowPassword,
      showPassword,
      checkForm,
      eyeOutline,
      eyeOffOutline,
    };
  },
};
</script>

<style scoped>
</style>