import {
    ref
} from "vue";

const manageMapError = ref(null);

const map = ref(null);
const mapCenter = ref(null);
const mapOrigin = ref({
    lat: 39.73896653716887,
    lng: -104.98733322509527
});
const mapRadius = ref(5);
const mapUserMarker = ref(null);
const mapZoom = ref(15);

const manageMap = () => {

    const resetMapCenter = () => {
        mapCenter.value = {
            lat: 39.73896653716887,
            lng: -104.98733322509527
        }
    }

    return {
        resetMapCenter,
        manageMapError,
        map,
        mapOrigin,
        mapCenter,
        mapRadius,
        mapUserMarker,
        mapZoom,
    }
}

export default manageMap;