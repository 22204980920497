<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/locations"></ion-back-button>
        </ion-buttons>
        <ion-title>Edit Location</ion-title>
        <ion-buttons slot="primary">
          <ion-button color="primary" @click="checkForm">Update</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="false">
      <form @submit.prevent="checkForm">
        <ion-grid>
          <ion-row>
            <ion-col sizeSm="8" offsetSm="2">
              <ion-card>
                <ion-list v-if="location">
                  <ion-list-header>
                    <ion-label>Location Information</ion-label>
                  </ion-list-header>
                  <ion-item>
                    <ion-label position="floating">Title</ion-label>
                    <ion-input v-model="location.title"></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Address</ion-label>
                    <ion-input v-model="location.address"></ion-input>
                    <ion-icon :icon="mapSharp" slot="end" @click="presentMap"></ion-icon>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Phone</ion-label>
                    <ion-input v-model="location.phone"></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Email</ion-label>
                    <ion-input v-model="location.email"></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Location Notes (internal use)</ion-label>
                    <ion-textarea v-model="location.notes"></ion-textarea>
                  </ion-item>
                </ion-list>
              </ion-card>

              <ion-card>
                <ion-list lines="none">
                  <ion-list-header>
                    <ion-label>Location Type</ion-label>
                  </ion-list-header>
                  <ion-item v-for="entry in location.locationTypes" :key="entry.label">
                    <ion-checkbox
                      slot="start"
                      :value="entry.isChecked"
                      :checked="entry.isChecked"
                      @ionChange="entry.isChecked = !entry.isChecked"
                    ></ion-checkbox>
                    <ion-label v-if="entry.label !== 'Other'">{{ entry.label }}</ion-label>
                    <ion-textarea v-else placeholder="Other..." clear-input v-model="entry.value"></ion-textarea>
                  </ion-item>
                </ion-list>
              </ion-card>

              <ion-card>
                <ion-list lines="none">
                  <ion-list-header>
                    <ion-label>Appointment Options</ion-label>
                  </ion-list-header>
                  <ion-item v-for="entry in location.appointmentTypes" :key="entry.label">
                    <ion-checkbox
                      slot="start"
                      :value="entry.isChecked"
                      :checked="entry.isChecked"
                      @ionChange="entry.isChecked = !entry.isChecked"
                    ></ion-checkbox>
                    <ion-label v-if="entry.label !== 'Other'">{{ entry.label }}</ion-label>
                    <ion-textarea v-else placeholder="Other..." clear-input v-model="entry.value"></ion-textarea>
                  </ion-item>
                </ion-list>
              </ion-card>

              <ion-card>
                <ion-list lines="none">
                  <ion-list-header>
                    <ion-label>Services Offered</ion-label>
                  </ion-list-header>
                  <ion-item v-for="entry in location.services" :key="entry.label">
                    <ion-checkbox
                      :value="entry.isChecked"
                      :checked="entry.isChecked"
                      @ionChange="entry.isChecked = !entry.isChecked"
                      slot="start"
                    ></ion-checkbox>
                    <ion-label v-if="entry.label !== 'Other'">{{ entry.label }}</ion-label>
                    <ion-textarea v-else placeholder="Other..." clear-input v-model="entry.value"></ion-textarea>
                  </ion-item>
                </ion-list>
              </ion-card>

              <ion-card>
                <ion-list lines="none">
                  <ion-list-header>
                    <ion-label>Payment Types Accepted</ion-label>
                  </ion-list-header>
                  <ion-item v-for="entry in location.paymentTypes" :key="entry.label">
                    <ion-checkbox
                      slot="start"
                      :value="entry.isChecked"
                      :checked="entry.isChecked"
                      @ionChange="entry.isChecked = !entry.isChecked"
                    ></ion-checkbox>
                    <ion-label v-if="entry.label !== 'Other'">{{ entry.label }}</ion-label>
                    <ion-textarea v-else placeholder="Other..." clear-input v-model="entry.value"></ion-textarea>
                  </ion-item>
                </ion-list>
              </ion-card>

              <ion-card>
                <ion-list>
                  <ion-list-header>
                    <ion-label>Hours of Operation</ion-label>
                  </ion-list-header>
                  <ion-item v-for="day in location.hours" :key="day.label" lines="none">
                    <ion-checkbox
                      slot="start"
                      :value="day.active"
                      :checked="day.active"
                      @ionChange="day.active = !day.active"
                    ></ion-checkbox>
                    <ion-label>{{ day.label }}</ion-label>
                    <ion-datetime
                      display-format="h:mm a"
                      v-model="day.open"
                      :value="day.open"
                      :disabled="!day.active"
                    ></ion-datetime>
                    <ion-datetime
                      display-format="h:mm a"
                      v-model="day.close"
                      :value="day.close"
                      :disabled="!day.active"
                    ></ion-datetime>
                  </ion-item>
                </ion-list>
                <ion-list v-if="errors.length > 0">
                  <ion-item v-for="error in errors" :key="error">{{ error }}</ion-item>
                </ion-list>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import useLocation from "@/composables/useLocation";
import getLocation from "@/composables/getLocation";
import useGeocode from "@/composables/useGeocode";
import useStorage from "@/composables/useStorage";
import { geoPoint } from "@/firebase";

import manageMap from "@/composables/manageMap";
import toast from "@/utilities/toast";
import loading from "@/utilities/loading";

import LocationSelector from "@/components/Map/LocationSelector";
import { mapSharp } from "ionicons/icons";

import {
  IonListHeader,
  IonRow,
  IonCol,
  IonCard,
  IonGrid,
  IonTextarea,
  IonIcon,
  IonCheckbox,
  IonDatetime,
  IonButton,
  IonInput,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  modalController,
} from "@ionic/vue";

export default {
  name: "EditLocation",

  components: {
    IonListHeader,
    IonRow,
    IonCol,
    IonCard,
    IonGrid,
    IonTextarea,
    IonIcon,
    IonCheckbox,
    IonDatetime,
    IonButton,
    IonInput,
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
  },

  props: ["id"],

  setup(props) {
    const { loadingStart, loadingStop } = loading();
    const { location, loadLocation } = getLocation();
    const { locationError, updateDoc } = useLocation();
    const {
      getAddressFromCoords,
      getCoordsFromAddress,
      geocodeError,
    } = useGeocode();
    const { saveFile, storageError } = useStorage();
    const { mapCenter } = manageMap();

    const router = useRouter();

    const mapLocationSelected = ref(false);
    const sessionAddress = ref(null);
    const sessionCoords = ref(null);
    const valuesSet = ref(false);

    const errors = ref([]);

    onMounted(async () => {
      await loadLocation(props.id);

      if (!valuesSet.value) {
        valuesSet.value = true;
        sessionAddress.value = location.value.address;
        sessionCoords.value = location.value.coordinates;
      }

      if (!location.value.hours) {
        location.value.hours = [
          {
            label: "Sunday",
            active: false,
            open: "08:00",
            close: "17:00",
          },
          {
            label: "Monday",
            active: false,
            open: "08:00",
            close: "17:00",
          },
          {
            label: "Tuesday",
            active: false,
            open: "08:00",
            close: "17:00",
          },
          {
            label: "Wednesday",
            active: false,
            open: "08:00",
            close: "17:00",
          },
          {
            label: "Thursday",
            active: false,
            open: "08:00",
            close: "17:00",
          },
          {
            label: "Friday",
            active: false,
            open: "08:00",
            close: "17:00",
          },
          {
            label: "Saturday",
            active: false,
            open: "08:00",
            close: "17:00",
          },
        ];
      }

      if (!location.value.services) {
        location.value.services = [
          {
            label: "Chip Repair",
            isChecked: false,
          },
          {
            label: "Headlight Restoration",
            isChecked: false,
          },
          {
            label: "Other",
            isChecked: false,
            value: "",
          },
        ];
      }

      if (!location.value.notes) {
        location.value.notes = "";
      }

      if (!location.value.locationTypes) {
        location.value.locationTypes = [
          {
            label: "Pop-up/Tent",
            isChecked: false,
          },
          {
            label: "Building",
            isChecked: false,
          },
          {
            label: "Other",
            isChecked: false,
            value: "",
          },
        ];
      }

      if (!location.value.paymentTypes) {
        location.value.paymentTypes = [
          {
            label: "Insurance",
            isChecked: false,
          },
          {
            label: "Credit/Debit Card",
            isChecked: false,
          },
          {
            label: "Cash",
            isChecked: false,
          },
          {
            label: "Other",
            isChecked: false,
            value: "",
          },
        ];
      }

      if (!location.value.appointmentTypes) {
        location.value.appointmentTypes = [
          {
            label: "Drive-up",
            isChecked: false,
          },
          {
            label: "Appointment",
            isChecked: false,
          },
          {
            label: "Other",
            isChecked: false,
            value: "",
          },
        ];
      }
    });

    const checkForm = () => {
      errors.value = [];
      if (!location.value.title) {
        toast("A title for this location is required.");
      }
      if (!location.value.address) {
        toast("An address for this location is required.");
      }
      if (location.value.title && location.value.address) {
        return handleEditLocation();
      }
    };

    const handleEditLocation = async () => {
      if (
        !mapLocationSelected.value &&
        location.value.address &&
        location.value.address !== sessionAddress.value
      ) {
        location.value.coordinates = await getCoordsFromAddress(
          location.value.address
        );
      }

      if (geocodeError.value) return;

      if (
        location.value.address !== sessionAddress.value ||
        location.value.coordinates !== sessionCoords.value
      ) {
        location.value.imageUrl = await saveFile(
          {
            lat: location.value.coordinates.latitude,
            lng: location.value.coordinates.longitude,
          },
          props.id,
          location.value.companyId
        );
      }

      if (storageError.value) return;

      loadingStart(5);
      await updateDoc(props.id, {
        title: location.value.title,
        address: location.value.address,
        phone: location.value.phone ?? "",
        email: location.value.email ?? "",
        isActive: location.value.isActive,
        coordinates: new geoPoint(
          location.value.coordinates.lat ?? location.value.coordinates.latitude,
          location.value.coordinates.lng ?? location.value.coordinates.longitude
        ),
        hours: location.value.hours,
        services: location.value.services,
        notes: location.value.notes,
        imageUrl: location.value.imageUrl,
        paymentTypes: location.value.paymentTypes,
        locationTypes: location.value.locationTypes,
        appointmentTypes: location.value.appointmentTypes,
      });
      loadingStop();

      if (!locationError.value) {
        router.go(-1);
      }
    };

    const presentMap = async () => {
      const modal = await modalController.create({
        component: LocationSelector,
        cssClass: "my-custom-class",
        componentProps: {
          title: "Select a location",
          coords: {
            lat: location.value.coordinates.latitude,
            lng: location.value.coordinates.longitude,
          },
          zoom: 17,
        },
      });

      modal.onWillDismiss().then(async (position) => {
        console.log(position);
        if (position.data) {
          mapLocationSelected.value = true;
          mapCenter.value = {
            lat: position.data.lat,
            lng: position.data.lng,
          };
          location.value.coordinates = {
            latitude: mapCenter.value.lat,
            longitude: mapCenter.value.lng,
          };
          location.value.address = await getAddressFromCoords(mapCenter.value);
        }
      });

      return modal.present();
    };

    return {
      checkForm,
      errors,
      presentMap,
      location,
      mapSharp,
      handleEditLocation,
    };
  },
};
</script>