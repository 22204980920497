<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <!-- <ion-title>ChipFix</ion-title> -->
        <ion-buttons slot="primary">
          <ion-button @click="handleShowResults">
            <span>
              {{ markers?.length }} location{{
              markers?.length === 1 ? "" : "s"
              }}
              found
            </span>
          </ion-button>
          <ion-button @click="handleOptionsMenu">
            <ion-icon slot="icon-only" :icon="filterOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <g-map @refreshMarkers="getMarkersByLocation"></g-map>
    </ion-content>
  </ion-page>
</template>

<script>
import { onUnmounted, ref } from "vue";
import GMap from "@/components/Map/GMap";
import Results from "@/components/Map/Results";
import manageMap from "@/composables/manageMap";
import getMarkers from "@/composables/getMarkers";

import { ellipsisVertical, filterOutline } from "ionicons/icons";

import {
  IonButton,
  IonIcon,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonToolbar,
  modalController,
  actionSheetController,
} from "@ionic/vue";

export default {
  name: "MapView",

  components: {
    IonButton,
    IonIcon,
    GMap,
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonToolbar,
  },

  setup() {
    const { markers, markersLoad } = getMarkers();
    const { mapCenter, mapRadius } = manageMap();
    const searchInputRef = ref(null);

    onUnmounted(() => {
      markers.value = [];
    });

    const getMarkersByLocation = async () => {
      await markersLoad(
        mapCenter.value.lat,
        mapCenter.value.lng,
        mapRadius.value
      );
    };

    const handleShowResults = async () => {
      const modal = await modalController.create({
        component: Results,
        cssClass: "my-custom-class",
        componentProps: {
          results: markers,
        },
      });

      modal.onWillDismiss().then((location) => {
        if (location.data) {
          mapCenter.value = { lat: location.data.lat, lng: location.data.lng };
        }
      });
      return modal.present();
    };

    const handleOptionsMenu = async () => {
      const actionSheet = await actionSheetController.create({
        header: "Search for locations within...",
        cssClass: "my-custom-class",
        buttons: [
          {
            text: "5 miles",
            cssClass: mapRadius.value === 5 ? "selected" : "",
            handler: () => {
              mapRadius.value = 5;
            },
          },
          {
            text: "10 miles",
            cssClass: mapRadius.value === 10 ? "selected" : "",
            handler: () => {
              mapRadius.value = 10;
            },
          },
          {
            text: "25 miles",
            cssClass: mapRadius.value === 25 ? "selected" : "",
            handler: () => {
              mapRadius.value = 25;
            },
          },
          {
            text: "50 miles",
            cssClass: mapRadius.value === 50 ? "selected" : "",
            handler: () => {
              mapRadius.value = 50;
            },
          },
        ],
      });
      return actionSheet.present();
    };

    return {
      filterOutline,
      markers,
      searchInputRef,
      handleOptionsMenu,
      getMarkersByLocation,
      handleShowResults,
      ellipsisVertical,
    };
  },
};
</script>

<style>
.selected {
  color: #e96432 !important;
}
</style>
