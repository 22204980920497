import {
    ref
} from "vue";
import {
    projectAuth,
} from "../firebase";
import toast from "../utilities/toast"

const registerError = ref(null);

const register = async (email, password) => {
    registerError.value = null;
    try {
        registerError.value = null;
        return await projectAuth.createUserWithEmailAndPassword(email, password);
    } catch (err) {
        console.log(err.message);
        registerError.value = "Unable to register user.";
        toast(err.message);
    }
}

const useRegister = () => {
    return {
        registerError,
        register
    }
}

export default useRegister;