import {
    ref
} from "vue";
import {
    projectAuth
} from "../firebase";
import toast from "../utilities/toast"

const loginError = ref(null);

const login = async (email, password) => {
    loginError.value = null;
    try {
        const res = await projectAuth.signInWithEmailAndPassword(email, password);
        loginError.value = null;
        return res;
    } catch (err) {
        console.log(err.message);
        loginError.value = 'Incorrect login credentials. Please try again.';
        await toast(loginError.value);
    }
}

const useLogin = () => {
    return {
        loginError,
        login
    }
}

export default useLogin;