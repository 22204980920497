<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Help</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-list id="site-options">
        <!-- <ion-item button @click="handleInfo" v-if="user.value?.uid">
          <ion-icon slot="start" :icon="informationOutline"></ion-icon>
          <ion-label>ChipFix Information</ion-label>
        </ion-item>-->
        <ion-item button @click="handleAbout">
          <ion-icon slot="start" :icon="phonePortraitOutline"></ion-icon>
          <ion-label>Using the app</ion-label>
        </ion-item>
        <ion-item :href="`mailto:${emailAddress}?subject=${emailSubject}`">
          <ion-icon slot="start" :icon="mailOutline"></ion-icon>
          <ion-label>Email Us</ion-label>
        </ion-item>
        <ion-item button @click="handleTerms">
          <ion-icon slot="start" :icon="shieldOutline"></ion-icon>
          <ion-label>Terms of Use</ion-label>
        </ion-item>
        <ion-item button @click="handlePrivacy">
          <ion-icon slot="start" :icon="documentOutline"></ion-icon>
          <ion-label>Privacy Policy</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router";
import {
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import {
  mailOutline,
  shieldOutline,
  documentOutline,
  informationOutline,
  phonePortraitOutline,
} from "ionicons/icons";
import { ref } from "vue";
import getUser from "@/composables/getUser";

export default {
  name: "About",

  components: {
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
  },

  setup() {
    const router = useRouter();
    const { user } = getUser();

    const emailAddress = ref("email@chipfix.app");
    const emailSubject = ref(
      `App Support ${user.value.uid ? "- User ID: " + user.value.uid : ""}`
    );

    const handleAbout = () => {
      router.replace({ name: "SiteSlides" });
    };

    const handleTerms = () => {
      router.push({ name: "SiteTerms" });
    };

    const handlePrivacy = () => {
      router.push({ name: "SitePrivacy" });
    };

    const handleInfo = () => {
      router.push({ name: "SiteInfo" });
    };

    return {
      user,
      handleAbout,
      handleTerms,
      handlePrivacy,
      handleInfo,
      emailAddress,
      emailSubject,
      mailOutline,
      shieldOutline,
      documentOutline,
      informationOutline,
      phonePortraitOutline,
    };
  },
};
</script>