<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/account/users"></ion-back-button>
        </ion-buttons>
        <ion-title>Add a new user</ion-title>
        <ion-buttons slot="primary">
          <ion-button color="primary" @click="checkForm">Create</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="false">
      <form @submit.prevent="checkForm">
        <ion-grid>
          <ion-row>
            <ion-col sizeSm="8" offsetSm="2">
              <ion-card>
                <ion-card-content>
                  <ion-list>
                    <ion-item>
                      <ion-label position="floating">Email</ion-label>
                      <ion-input type="email" v-model="email" required></ion-input>
                    </ion-item>
                    <ion-item>
                      <ion-label position="floating">Password</ion-label>
                      <ion-input type="password" v-model="password" required></ion-input>
                    </ion-item>
                    <ion-item>
                      <ion-label position="floating">Confirm Password</ion-label>
                      <ion-input type="password" v-model="confirmPassword" required></ion-input>
                    </ion-item>
                  </ion-list>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import loading from "@/utilities/loading";
import { ref } from "vue";
import { useRouter } from "vue-router";
import toast from "@/utilities/toast";
import manageUsers from "@/composables/manageUsers";

import {
  IonCardContent,
  IonRow,
  IonCol,
  IonCard,
  IonGrid,
  IonLabel,
  IonInput,
  IonList,
  IonButton,
  IonItem,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

export default {
  name: "Register",

  components: {
    IonCardContent,
    IonRow,
    IonCol,
    IonCard,
    IonGrid,
    IonLabel,
    IonInput,
    IonList,
    IonButton,
    IonItem,
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
  },

  setup() {
    const router = useRouter();

    const { addUser, manageUsersError } = manageUsers();
    const { loadingStart, loadingStop } = loading();

    const email = ref("");
    const password = ref("");
    const confirmPassword = ref("");

    const checkForm = () => {
      if (!email.value) {
        toast("An email address is required.");
      } else if (password.value !== confirmPassword.value) {
        toast("Passwords do not match.");
      } else if (password.value.length < 6) {
        toast("Password must be at least 6 characters long.");
      } else {
        return handleAddUser();
      }
    };

    const handleAddUser = async () => {
      loadingStart(20);
      await addUser(email.value, password.value);
      loadingStop();
      if (!manageUsersError.value) {
        router.go(-1);
      }
    };

    return { email, password, confirmPassword, handleAddUser, checkForm };
  },
};
</script>

<style scoped>
</style>