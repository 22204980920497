<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/account"></ion-back-button>
        </ion-buttons>
        <ion-title>Users</ion-title>
        <ion-buttons slot="primary">
          <ion-icon
            slot="icon-only"
            color="primary"
            :icon="informationCircleOutline"
            @click="handleUserInfo"
          ></ion-icon>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-list ref="slidingItems">
        <ion-item-sliding v-for="user in usersList" :key="user.id">
          <ion-item>
            <ion-label :class="user.isActive ? 'active-user' : ''">
              <h2>{{ user.email }}</h2>
            </ion-label>
          </ion-item>

          <ion-item-options side="start">
            <ion-item-option color="light">
              <ion-toggle @ionChange="handleToggleUser($event, user.id)" :checked="user.isActive"></ion-toggle>
            </ion-item-option>
          </ion-item-options>

          <ion-item-options side="end">
            <ion-item-option color="danger" @click="presentDeleteConfirm(user.id)">
              <ion-icon slot="icon-only" :icon="trashBinSharp"></ion-icon>
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </ion-list>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="handleAddUser">
          <ion-icon :icon="personAddSharp"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router";
import {
  personAddSharp,
  trashBinSharp,
  informationCircleOutline,
} from "ionicons/icons";
import loading from "@/utilities/loading";
import manageUsers from "@/composables/manageUsers";

import {
  IonLabel,
  IonItem,
  IonToggle,
  IonItemOption,
  IonItemOptions,
  IonIcon,
  IonItemSliding,
  IonList,
  IonFab,
  IonFabButton,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  alertController,
} from "@ionic/vue";
import { onMounted, ref, watch } from "vue";

export default {
  name: "ManageUsers",

  components: {
    IonLabel,
    IonItem,
    IonToggle,
    IonItemOption,
    IonItemOptions,
    IonIcon,
    IonItemSliding,
    IonList,
    IonFab,
    IonFabButton,
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
  },

  setup() {
    const router = useRouter();
    const { loadingStart, loadingStop } = loading();
    const { getUsers, users, toggleUser, deleteUser } = manageUsers();
    const usersList = ref([]);
    const slidingItems = ref(null);

    onMounted(async () => {
      await loadingStart(5);
      await getUsers();
      loadingStop();
    });

    watch(users, (val) => {
      usersList.value = val;
    });

    const handleAddUser = () => {
      router.push({ name: "AccountUserNew" });
    };

    const handleToggleUser = async (e, id) => {
      const isActive = e.detail.checked;
      await toggleUser(id, isActive);
    };

    const handleUserInfo = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Sub-Accounts",
        subHeader: "Swipe the user to access options",
        message: `Users who are toggled on are able to view, create, edit and delete locations.<br><br>Users who are toggled off are only able to view the location data.`,
        buttons: ["OK"],
      });
      return alert.present();
    };

    const presentDeleteConfirm = async (id) => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Are you sure?",
        message: "This action can not be undone.",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: async () => {
              await slidingItems?.value?.$el.closeSlidingItems();
            },
          },
          {
            text: "Delete",
            handler: async () => {
              loadingStart(5);
              await deleteUser(id);
              loadingStop();
            },
          },
        ],
      });
      return alert.present();
    };

    return {
      handleUserInfo,
      informationCircleOutline,
      slidingItems,
      personAddSharp,
      trashBinSharp,
      handleAddUser,
      handleToggleUser,
      usersList,
      presentDeleteConfirm,
    };
  },
};
</script>

<style scoped>
.active-user {
  --color: #e96432;
}
.user-not-active {
  --color: #fff;
}
</style>